import 'nodelist-foreach-polyfill'
import { Collapse } from 'bootstrap'

export default function filterCollapse(selector) {
  const filterCollapsibles = document.querySelectorAll(selector);
  
  filterCollapsibles.forEach((ele) => {
    const allCheckboxes = ele.querySelectorAll(".form-check");;
    const collapseLink = ele.querySelectorAll(".collapse-button")[0]
    const beforeCollapseFilters = Array.prototype.slice.call(allCheckboxes).slice(0, 3);
    const beforeCollapseElement = ele.getElementsByClassName("js-before-filter-collapse")[0];
  
    beforeCollapseFilters.forEach((ele) => {
      beforeCollapseElement.appendChild(ele)
    })
    
    if (allCheckboxes.length > 3) {
      collapseLink.addEventListener("click", function(e) {
        const title = this.parentElement.firstElementChild.innerText;
        const titlePrefix = title === "Expertise" ? 'Show Fewer Areas of' : 'Show Fewer'
        
        if (collapseLink.classList.contains('collapsed')) {
          collapseLink.innerText = 'Show More'
        } else {
          collapseLink.innerText = titlePrefix + ' ' + title
        };
      }, false);
    } else {
      ele.removeChild(collapseLink);
    }

  })
}