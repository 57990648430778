<template>
  <div class="d-flex justify-content-start flex-column flex-md-row position-relative ticket-buttons js-ga-scope-event-registration-ticket" v-if="loaded">
    <a class="btn my-2 my-md-auto btn-status js-ga-track-events"
      data-bs-toggle="modal"
      href="#register"
      v-bind:class="[ ticket == null ? 'disabled' : '', upgrade_ticket == null ? 'btn-primary' : 'btn-outline-primary' ]"
      v-if="ticket && new_registration.registrant_id">Get Your {{ ticket.price | currencyOrFree(ticket.currency) }} {{ticket.name}} Ticket</a>
    <a class="btn btn-primary my-2 my-md-auto ms-md-3 fade text-truncate js-ga-track-events"
      data-bs-toggle="modal"
      href="#upgrade"
      data-gacategory="acquisition"
      data-gaevent="upgrade"
      data-galabel="event registration"
      @click="trackEvent(this, $event)"
      v-bind:class="[ upgrade_ticket == null ? '' : 'show in' ]"
      v-if="upgrade_ticket && new_registration.registrant_id">{{ trialUpgradeCta }} for a {{ upgrade_ticket.price | currencyOrFree(upgrade_ticket.currency) }} {{upgrade_ticket.name}} Ticket</a>
    <a class="btn btn-primary my-2 my-md-auto js-ga-track-events"
      data-bs-toggle="modal" 
      href="#join"
      data-gacategory="acquisition" 
      data-gaevent="cta" 
      data-galabel="event registration"
      @click="trackEvent(this, $event)"
      v-if="!new_registration.registrant_id">Register</a>
    <a class="btn btn-link my-2 my-md-auto js-ga-track-events"
      :href="login_url"
      data-gacategory="acquisition"
      data-gaevent="cta"
      data-galabel="event login"
      @click="trackEvent(this, $event)"
      v-if="!new_registration.registrant_id">Sign In</a>
  </div>
  <div class="d-flex justify-content-start position-relative ticket-buttons" v-else>
    <button class="btn btn-primary my-lg-auto btn-status" disabled>Fetching&hellip;</button>
  </div>
</template>

<script>
  import { Modal } from 'bootstrap'
  import gaTrackEvents from '../javascripts/gaTrackEvents.js'
  import sendAirbrakeError from '../javascripts/sendAirbrakeError.js'
  import ax from '../javascripts/axiosInit.js'

  export default {
    name: 'EventRegistrationTicket',
    props: {
      new_event_registration_url: {
        type: String,
        required: true
      },
      login_url: {
        type: String,
        required: true
      }
    },
    data: () => ({
      loaded: false,
      can_trial: false,
      new_registration: {},
      ticket: null,
      upgrade_ticket: null
    }),
    computed: {
      trialUpgradeCta: function () {
        const self = this
        if (self.can_trial == true) {
          return 'Start a Free Trial'
        } else { 
          return 'Upgrade'
        }
      }
    },
    methods: {
      trackEvent: function(ele, e) {
        this.$emit('clicked', ele)
      }
    },
    created: function () {
      const self = this
      // ax = axios;
      ax.get(self.new_event_registration_url)
        .then(function (response) {
          self.new_registration = response.data
          self.can_trial = response.data.can_trial
          if(response.data.ticket) {
            self.ticket = response.data.ticket
          }
          if(response.data.upgrade_ticket) {
            self.upgrade_ticket = response.data.upgrade_ticket
          }
        })
        .catch(function (error) {
          // handle error
          sendAirbrakeError(error)
        })
        .then(function () {
          // always executed
          self.loaded = true
          setTimeout(function() {
            gaTrackEvents('.js-ga-scope-event-registration-ticket .js-ga-track-events')
          }, (2))
        });
    }
  }
</script>