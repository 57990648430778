'use strict';
// Google Optimize jams all these style tags in the head that cause
// problems for pages not being A/B Tested
export default function stripOptimizeStyles () {
  const styleTags = document.querySelectorAll('head > style:not([type]');
  styleTags.forEach( function(tag) {
    if (!tag.getAttribute('id')) {
      tag.remove(); 
    } 
  });
}