'use strict'
export default function gaTrackEvents (selector = '.js-ga-track-events') {
  if (typeof ga !== 'undefined' && ga !== null) {
    const eles = document.querySelectorAll(selector)
    Array.prototype.forEach.call(eles, function(ele) {
      const eleData = ele.dataset
      const eleEvent = eleData.gaevent != undefined ? eleData.gaevent : 'click'
      const category = eleData.gacategory != undefined ? eleData.gacategory : ''
      const action = eleData.gaaction != undefined ? eleData.gaaction : ''
      ele.addEventListener(eleEvent, function(event) {
        let label = eleData.galabel != undefined ? eleData.galabel : ''
        if (ele.tagName === 'FORM') {
          if (ele.checkValidity() === false) {
            label = 'invalid'
          } else {
            if (label == '') {
              label = 'valid'
            } else {
              label
            }
          }   
        }
        if (ele.tagName === 'SELECT' && label == '') {
          label = ele.value
        }
        ga('send', 'event', category, action, label)
      })
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  gaTrackEvents('.js-ga-track-events')
})