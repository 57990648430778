'use strict'
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
Vue.use(TurbolinksAdapter)

export default function articleCardDecks (selector = '.js-articles-card-deck') {
  const eles = document.querySelectorAll(selector)
  if (eles.length > 0) {
    const articleCardDeck = () => import('ArticleCardDeck.vue')
    Array.prototype.forEach.call(eles, function(ele) {
      new Vue({ 
        el: ele,
        render: h => h(articleCardDeck, {
          props: {
            url: ele.dataset.url,
            rowStyle: ele.dataset.rowstyle
          }
        })
      })
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  articleCardDecks()
})