'use strict'
import 'nodelist-foreach-polyfill'
import { ScrollSpy } from 'bootstrap'

export default function bsScrollSpy (selector = '.js-scrollspy') {
  const spyEles = document.querySelectorAll(selector)
  spyEles.forEach((spyEle) => {
    if (ScrollSpy.getInstance(spyEle)) {
      ScrollSpy.getInstance(spyEle).refresh()
    } else {
      new ScrollSpy(document.body, {
        target: spyEle
      })
    }
  })
}

window.addEventListener('turbolinks:load', () => {
  bsScrollSpy()
})