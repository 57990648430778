'use strict';
export default function toggleVisibilityElementOnChange (selector) {
  if (document.querySelector(selector) !== null) {
    let inputs = document.querySelectorAll(selector);
    Array.prototype.forEach.call(inputs, function (input) {

      if (input.hasAttribute('data-target')) {
        let hasBtnGrp = input.parentElement.parentElement.className.split(' ').indexOf('btn-group-toggle') >= 0;
        let targetEles = document.querySelectorAll(input.getAttribute('data-target'));
        input.addEventListener('change', function (event) {
          hideShow(input, targetEles, hasBtnGrp);
        });
      } else {
        return
      }
    });

    function hideShow(input, targetEles, hasBtnGrp) {
      if (hasBtnGrp == true) {
        let btns = input.parentElement.parentElement.querySelectorAll('.btn.active');
        for (let i = 0; i < btns.length; i++) {
          btns[i].classList.remove('active');
        }
      }
      for (let i = 0; i < targetEles.length; i++) {
        if (targetEles[i].hasAttribute('data-show') && targetEles[i].getAttribute('data-show') === input.value ) {
          targetEles[i].classList.add('show');
          if (hasBtnGrp == true) {
            input.parentElement.classList.add('active');
          }
        } else {
          targetEles[i].classList.remove('show');
        }
      }
    }

  }
}

document.addEventListener('turbolinks:load', () => {
  toggleVisibilityElementOnChange('.js-toggle-visibility-on-change');
});