'use strict'
import '@popperjs/core'
import { Tooltip } from 'bootstrap'

export default function bsTooltip (selector = '[data-bs-toggle="tooltip"]') {
  const tooltipTriggerEles = [].slice.call(document.querySelectorAll(selector))
  const tooltipList = tooltipTriggerEles.map(function (tooltipTriggerEle) {
    if (Tooltip.getInstance(tooltipTriggerEle)) {
      return
    } else {
      new Tooltip(tooltipTriggerEle, {
        placement: 'auto',
        html: true
      })   
    }
  })
}

window.addEventListener('turbolinks:load', () => {
  bsTooltip()
})