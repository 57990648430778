'use strict'
// polyfill
require('intersection-observer')

const stickyNavObserver = (selector) => {
  if(!!window.IntersectionObserver){
    // Element attached to the observer
    const eles = document.querySelectorAll(selector)
    const bodyEle = document.getElementsByTagName('body')[0]
    const offsetTop = (0 - document.querySelectorAll('body > .site-nav')[0].offsetHeight).toString()

    Array.prototype.forEach.call(eles, function(ele){
      let eleData = ele.dataset
      let stickyElement = document.querySelectorAll(ele.dataset.target)[0]
      let observer = new IntersectionObserver((entries, observer) => { 
        entries.forEach(entry => {
          if(entry.intersectionRatio!=1){
            stickyElement.classList.add('stuck')
            bodyEle.classList.add('sticky-stuck')
          } else {
            stickyElement.classList.remove('stuck')
            bodyEle.classList.remove('sticky-stuck')
          }

        })
      }, {rootMargin: offsetTop + 'px 0px 0px 0px'})
      observer.observe(ele)
    })
  }
}

export { stickyNavObserver }

document.addEventListener('turbolinks:load', () => {
  if(document.querySelectorAll('.js-sticky-observer').length > 0){
    stickyNavObserver('.js-sticky-observer')
  }
})