export default function truncate (text, length, clamp) {
  text = text || '';
  length = length || 30;
  clamp = clamp || "...";
  if (text.length <= length) return text;
  
  var slicedText = text.slice(0, length - clamp.length);
  var last = slicedText.length - 1;
  
  while (last > 0 && slicedText[last] !== ' ' && slicedText[last] !== clamp[0]) last -= 1;

  // Fix for case when text dont have any `space`
  last = last || length - clamp.length;
  slicedText =  slicedText.slice(0, last);
  return slicedText + clamp;
}