'use strict';
import storageAvailable from 'storageAvailable.js'

const dropGaSession = function(gaTrackedEles){
  Array.prototype.forEach.call(gaTrackedEles, function(ele){
    ele.addEventListener('click', function(event){
      sessionStorage.setItem('gaTrackEvent', ele.dataset.gacategory);
    })
  });
}

const fireGaEventFromSession = function() {
  const allowedQueries = ['post_types', 'types', 'publishers','user', 'chapters', 'chapter', 'goals',
  'level', 'schools', 'companies', 'job_functions', 'groups', 'industries', 'location', 'kind', 'career_stages',
  'expertises', 'search', 'series', 'features']
  const urlParser = require('query-string');
  const parsedUrl = new urlParser.parse(location.search, {arrayFormat: 'bracket'});
  ga('send', 'event', sessionStorage.gaTrackEvent, 'full_search', location.search);

  Object.entries(parsedUrl).forEach( function(e){
    if(!!e[1] && allowedQueries.indexOf(e[0]) >= 0){
      if (e[0].indexOf('search') >= 0){
        ga('send', 'event', 'text', 'search', e[1]);
      } else if ( Array.isArray(e[1]) ) {
        e[1].forEach( function(value) {
          ga('send', 'event', sessionStorage.gaTrackEvent, e[0], value);
        })
      } else {
        ga('send', 'event', sessionStorage.gaTrackEvent, e[0], e[1]);
      }
    }
  })
  sessionStorage.clear();
}

document.addEventListener('turbolinks:load', function() {
  if (typeof ga !== 'undefined' && ga !== null) {
    const gaTrackedEles = document.querySelectorAll('.js-ga-track-on-submit');
    dropGaSession(gaTrackedEles);

    if(storageAvailable('sessionStorage') && !!sessionStorage.gaTrackEvent){
      fireGaEventFromSession();
    }
  }
});