'use strict';

const Clipboard = require('clipboard')
import { Tooltip } from 'bootstrap'

const initClipboard = () => {
    const clips = document.querySelectorAll('.js-copy-link-to-clipboard');
    Array.prototype.forEach.call(clips, function(ele) {
        const clipboard = new Clipboard('.js-copy-link-to-clipboard', {
            container: document.getElementById(ele.dataset.clipboardContainer)
        });

        clipboard.on('success', function (e) {
            const ele = e.trigger
            const clipTip = Tooltip.getOrCreateInstance(ele, {
              trigger: 'manual'
            })
            ele.classList.remove('ei-clipboard')
            ele.classList.add('ei-clipboard-check', 'text-success')
            if(clipTip) {
                clipTip.show()
            }
            window.setTimeout(function() {
                ele.classList.remove('ei-clipboard-check', 'text-success')
                ele.classList.add('ei-clipboard')
                if(clipTip) {
                    clipTip.hide()
                }
            }, 1500)

        });
    });
}

export { initClipboard }

document.addEventListener('turbolinks:load', () => {
    initClipboard()
});