'use strict';


const initDateTimeEndDateSetter = () => {
    const start_date_ele = $('.js_event_start_date #event_start_date');
    const end_date_ele = $('.js_event_end_date #event_end_date');
    const start_time_ele = $('.js_event_start_time #event_start_time');
    const end_time_ele = $('.js_event_end_time #event_end_time');

    start_date_ele.change(() => {
        if ( start_date_ele.val() !== "" ) {
            updateEndDate();
            updateEndTime();
        }
    });

    start_time_ele.change(() => {
        if ( start_date_ele.val() !== "" ) {
            updateEndDate();
            updateEndTime();
        }
    });

    const updateEndDate = () => {

        let start_date_obj = new Date(start_date_ele.val() + "T00:00:00").setHours(0,0,0,0);
        let end_date_obj = new Date(end_date_ele.val() + "T00:00:00").setHours(0,0,0,0);

        if (end_date_ele.val() == "" || start_date_obj > end_date_obj ) {
            end_date_ele.val(start_date_ele.val());
        }

    }

    const updateEndTime = () => {
        // Get Start Time
        let start_time_val = $(start_time_ele).find(':selected').val();
        let start_time_split = start_time_val.split(' ');
        let start_am_pm = start_time_split[1];
        let start_time_hr_min = start_time_split[0];
        let start_hr_min_split = start_time_hr_min.split(':')
        let start_time_hr = parseInt(start_hr_min_split[0]);
        let start_time_min = start_hr_min_split[1];
        //Get End Time
        let end_time_val = $(end_time_ele).find(':selected').val();
        let end_time_split = end_time_val.split(' ');
        let end_am_pm = end_time_split[1];
        let end_time_hr_min = end_time_split[0];
        let end_hr_min_split = end_time_hr_min.split(':')
        let end_time_hr = parseInt(end_hr_min_split[0]);
        let end_time_min = end_hr_min_split[1];

        let start_time_date_obj = new Date();
        let end_time_date_obj = new Date();
        

        if ( start_am_pm == "PM" ) {
            if ( start_time_hr != 12 ) {
                start_time_hr += 12;
            }
        } else { //start time is AM
            if ( start_time_hr == 12 ) {
                start_time_hr = 0;
            }
        }

        if ( end_am_pm == "PM" ) {
            if ( end_time_hr != 12 ) {
                end_time_hr += 12;
            }
        }

        start_time_date_obj.setHours(start_time_hr, parseInt(start_time_min), 0);
        end_time_date_obj.setHours(end_time_hr, parseInt(end_time_min), 0);

        let new_time = start_time_hr + 1;
        let new_am_pm = "AM";

        if (new_time == 12) {
            new_am_pm = "PM";
        } else if ( new_time < 12 ) {
            //do nothing
        } else {
            if ( new_time == 24 ){
                new_time = 12;
                new_am_pm = "AM";
            } else if ( new_time > 24 ) {
                new_time -= 24;
                new_am_pm = "AM";
            } else {
                //between 12 and 24
                new_time -= 12;
                new_am_pm = "PM";
            }
        }

        let end_time_to_select = $(end_time_ele).find("option[value='" + new_time + ":" + start_time_min + " " + new_am_pm + "']")

        let selected_start_time_index = $(start_time_ele).find(':selected').index();
        let current_end_time_index = $(end_time_ele).find(':selected').index();
        let new_index = 0;


        //If no end time has been selected then select a time +1hr from start time
        if ( current_end_time_index == 0 ) {
            new_index = end_time_to_select.index();
        }

        //If event dates are the same but start_time is after end_time set end time 1hr forward
        else if ( start_date_ele.val() == end_date_ele.val() ) {
            if ( start_time_date_obj >= end_time_date_obj ) {
                if ( start_time_hr >= 23 && start_am_pm == "PM" ) {
                    // end of the day, don't update end time/date
                } else {
                    new_index = end_time_to_select.index();
                    setEndTimeIndex(new_index);
                }
            }
        }

        // Change end_time if the original index was 0
        if ( selected_start_time_index > 0 && current_end_time_index == 0 ) {
            if ( start_time_hr >= 23 && start_am_pm == "PM" ) {
                // end of the day, don't update end time/date
            } else {
                setEndTimeIndex(new_index);
            }
        }
    }

    const setEndTimeIndex = (new_end_time_index) => {
        $('#event_end_time option').eq(new_end_time_index).prop('selected', true);
    }
}

export { initDateTimeEndDateSetter }

document.addEventListener('turbolinks:load', () => {
    initDateTimeEndDateSetter()
});
