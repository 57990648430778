import storageAvailable from 'storageAvailable.js'

document.addEventListener('turbolinks:load', function() {

  $('.js-add-filter-on-change').on('change', function() {
    var $list = $('.js-append-filters');
    var value = $(this).val();
    var $option = $(this).find("option[value='" + value + "']");
    var name = ($(this).data('type')) + "[]";
    var text = $option.html();
    if (value !== '') {
      $list.prepend(createFilterItem(name, value, text));
    }
    if ($(this).data('search-on-change')) {
      if (storageAvailable('sessionStorage')){
       sessionStorage.setItem('gaTrackEvent', 'basic filter');
      }
      $(this).closest('form').submit();
    } else {
      $option.detach();
    }
  });
});