'use strict'
// require('addevent')
// import { addeventatc, addeventasync, addeventReady } from '../../../node_modules/addevent/atc.min.js'

window.addeventasync = function() {
  addeventatc.settings({
    css:        false,
    license:    'acQLTyWbtzRjVMbnLmVE40369',
    appleical:  { show: true, text: "Apple Calendar" },
    google:     { show: true, text: "Google" },
    outlook:    { show: true, text: "Outlook" },
    outlookcom: { show: true, text: "Outlook.com <em>(online)</em>" },
    yahoo:      { show: false }
  })

  addeventatc.register('button-dropdown-click', function(obj) {
    if (typeof ga !== 'undefined' && ga !== null) {
      ga('send', 'event', 'Add to Calendar', 'Service', obj.service);
    }
  })
}

document.addEventListener('turbolinks:load', () => {
  if (addeventatc) {
    setTimeout(function() {
      addeventatc.refresh()
    }, 200)
  }
})
