
import sendAirbrakeError from 'sendAirbrakeError.js';

export default function updateAfterSort(sorted, url) {
  let xhr = new XMLHttpRequest();
  xhr.open('PATCH', url);
  let csrfToken = $('meta[name="csrf-token"]').attr('content')
  xhr.setRequestHeader('x-csrf-token', csrfToken);
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader("Accept", "application/json");
  xhr.onreadystatechange = function() {
    if (xhr.status == 200) {
      return window.location.reload(true);
    } else {
      sendAirbrakeError(xhr.status);
    }
  }
  xhr.send(JSON.stringify({order: sorted}));
}