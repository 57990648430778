'use strict'
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
import filters from 'filters'
Vue.use(filters)
Vue.use(TurbolinksAdapter)

export default function userCardDecks (selector = '.js-users-card-deck') {
  const eles = document.querySelectorAll(selector)
  if (eles.length > 0) {
    const UserCardDeck = () => import('UsersByChapter.vue')
    Array.prototype.forEach.call(eles, function(ele) {
      new Vue({ 
        el: ele,
        render: h => h(UserCardDeck, {
          props: {
            url: ele.dataset.url,
            rowStyle: ele.dataset.rowstyle
          }
        })
      })
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  userCardDecks()
})