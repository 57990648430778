'use strict'
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
import filters from 'filters'
import 'nodelist-foreach-polyfill'
Vue.use(filters)
Vue.use(TurbolinksAdapter)
import { Collapse, Modal } from 'bootstrap'
import resetModal from 'resetModal.js'
import eventCardDecks from 'eventCardDecks.js'
import trackContent from 'trackContent.js'

export default function eventRegistrationsCheckout (ele) {
  const eles = ele.querySelectorAll('.js-registration-checkout')
  if (eles.length > 0) {
    const EventRegistrationCheckout = () => import('EventRegistrationCheckout.vue')
    Array.prototype.forEach.call(eles, function(ele) {
      const data = ele.dataset
      new Vue({ 
        el: ele,
        render: h => h(EventRegistrationCheckout, {
          props: {
            new_event_registration_url: data.newEventRegistrationUrl,
            create_event_registration_url: data.createEventRegistrationUrl,
            validate_coupon_event_registrations_url: data.validateCouponEventRegistrationsUrl,
            customer_default_source_url: data.customerDefaultSourceUrl,
            customer_update_payment_info_url: data.customerUpdatePaymentInfoUrl,
            translation_obj: JSON.parse(data.translationObj),
            translation_offline_disclaimer_html: data.translationOfflineDisclaimerHtml,
            translation_online_disclaimer_html: data.translationOnlineDisclaimerHtml
          }
        })
      })
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  const registrationModals = document.querySelectorAll('.js-registration-modal')
  registrationModals.forEach((ele) => {
    const modalInt = Modal.getOrCreateInstance(ele)
    ele.addEventListener('show.bs.modal', function (event) {
      eventRegistrationsCheckout(ele)
      trackContent({ action: 'modal show registration'})
      eventCardDecks('.js-registration-modal .js-registration-checkout-card-deck')
      document.addEventListener('turbolinks:before-cache', () => {
        resetModal()
      })
    })
  })
})