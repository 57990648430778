export default function submitCocoonFormOnChildRemoved(formContainer) {
  const goalsFormEle = $(formContainer).find('form');
  $(formContainer).on("cocoon:after-remove", () => {
    goalsFormEle.submit();
  });
}

document.addEventListener("turbolinks:load", () => {
  $.fn.submitCocoonFormOnChildRemoved = submitCocoonFormOnChildRemoved;
  $.fn.submitCocoonFormOnChildRemoved('.js-profile-goals-expertise-container');
})
