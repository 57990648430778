import 'nodelist-foreach-polyfill';

export default function moreOptionsDropDown(buttonsClass, moreOptionsDropDownId) {
  const moreOptionsDropDown = document.getElementById(moreOptionsDropDownId);
  const dropdownButtons = document.querySelectorAll(buttonsClass);
  
  dropdownButtons.forEach( function(ele) {
    ele.addEventListener("click", function(e) {
      if (moreOptionsDropDown.classList.contains("show")) {
        moreOptionsDropDown.classList.remove("show");
        moreOptionsDropDown.getElementsByClassName("dropdown-menu")[0].classList.remove("show");
      };
    }, false);
  });
  
  document.body.addEventListener("click", function(e) { 
    if (e.target.offsetParent === null) {
      return;
    } else if (
      moreOptionsDropDown !== null &&
      moreOptionsDropDown.classList.contains("show") && 
      !e.target.offsetParent.classList.contains("dropdown-menu") &&
      !e.target.offsetParent.classList.contains("form-check")
    ) {
      moreOptionsDropDown.classList.remove("show");
      moreOptionsDropDown.getElementsByClassName("dropdown-menu")[0].classList.remove("show");
    };
  }, false);
};