import { titleize } from "../filters/functions.js";

export default function replaceInnerText(button, target) {
  const count = target.querySelectorAll("input[type='checkbox']:checked").length;
  const originalTitle = titleize(button.dataset.title);
  
  if (count === 1) {
    const title = target.querySelectorAll("input[type='checkbox']:checked")[0].value;
    
    button.classList.remove("btn-outline-primary");
    button.classList.add("btn-primary");
    button.innerText = titleize(title);
  } else if (count > 1){
    // const newText = originalTitle + ' (' + count.toString() + ')';
    const newText = originalTitle + ' <span class="badge bg-light text-dark">' + count.toString() + '</span>';
    
    button.classList.remove("btn-outline-primary");
    button.classList.add("btn-primary");
    button.innerHTML = newText;
  } else {
    button.classList.remove("btn-primary");
    button.classList.add("btn-outline-primary");
    button.innerHTML = originalTitle;
  };
};