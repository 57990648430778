'use strict'
import ax from 'axiosInit.js'
import sendAirbrakeError from 'sendAirbrakeError.js'
import routesConfig from 'routesConfig.js.erb'
const Url = require('url-parse')

// Prams can be 'action: my-action'
export default function trackContent (paramsObj = {}) {
  const pathsToIgnore = ['admin','styleguide']

  // Check for User first
  if (Cookies.get('elle_identify') || document.getElementById('js_current_user_data')) {
    const trackingParamsEle = document.querySelector('.js-track-content')
    const urlObj = new Url(window.location.href, true)
    const pathsArray = urlObj.pathname.split('/')
    const intersections = pathsArray.filter(e => pathsToIgnore.indexOf(e) !== -1)
    if (trackingParamsEle && (intersections.length < 1)) {
      const routes = routesConfig()
      const trackingPath = routes['trackings']
      const trackingParams = JSON.parse(trackingParamsEle.textContent)
      Object.assign(trackingParams, { data: { url: urlObj } } )
      let mergedParams = Object.assign({}, trackingParams, paramsObj)

      ax.post(trackingPath, mergedParams)
        .then(function (response) {
        })
        .catch(function (error) {
          sendAirbrakeError(error)
        })
        .then(function () {})
    }
  }
}

document.addEventListener('turbolinks:load', () => {
  trackContent()
})