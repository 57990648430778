'use strict'
export default function imageUploader (selector, callback = false) {
  if (document.querySelector(selector) !== null) {
    let imageUploadEles = document.querySelectorAll( selector );
    Array.prototype.filter.call(imageUploadEles, function(ele) {
      let dropArea = ele;
      let fileEle = ele.querySelector( "input[type='file']" );
      let labelEle = ele.querySelector( 'label' );
      let preEle = ele.querySelector( '.js-img-preview' );
      let maxMb = getMaxSize();

      // Prevent default drag behaviors
      ['dragenter','dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false);
        document.body.addEventListener(eventName, preventDefaults, false);
      });

      // Highlight drop area when item is dragged over it
      ['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, highlight, false);
      });

      ['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false);
      });

      // Handle dropped files
      dropArea.addEventListener('drop', handleDrop, false);

      function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
      }

      function highlight(e) {
        return dropArea.classList.add('focus');
      }

      function unhighlight(e) {
        return dropArea.classList.remove('focus');
      }

      function checkSize(file) {
        // Bytes to megabytes
        let bytes = ( file.size >>> 20 ) + '.' + ( file.size & (2*0x3FF ) );
        return bytes < maxMb;
      }

      function getMaxSize() {
        if (fileEle.getAttribute('data-maxmb')) {
          return parseInt(fileEle.getAttribute('data-maxmb'), 10);
        } else {
          return 5.0
        }
      }

      // Check the extension against the accept attr
      function checkExt(file) {
        let acceptStr = fileEle.getAttribute('accept').trim();
        return acceptStr.indexOf(file.type) >= 0;
      }

      // Run checks if they use drag and drop
      function handleDrop(e) {
        let dt = e.dataTransfer;
        let file = dt.files[0];
        if ( checkSize(file) && checkExt(file) ) {
          // This is the only way to set the "value of a file input"
          fileEle.files = dt.files;
          return previewFile(file);
        } else {
          return handleError();
        }
      }

      function handleSuccess() {
        fileEle.classList.remove('is-invalid');
        return fileEle.classList.add('is-valid');
      }

      function handleError() {
        fileEle.classList.remove('is-valid');
        return fileEle.classList.add('is-invalid');
      }

      function previewFile(file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function(e) {
          handleSuccess();
          preEle.style.backgroundImage = 'url("' + e.target.result + '")';
          preEle.classList.remove('empty', 'ei-image-alt');
          return labelEle.innerText = file.name;
        }
      }

      // Run checks if they use the actual file input
      fileEle.addEventListener('change', (e) => {
        if (e.target.files && e.target.files.length === 1) {
          let file = e.target.files[0];
          if ( checkSize(file) && checkExt(file) ) {
            return previewFile(file);
          } else {
            return handleError(file);
          }
        }
      });

      preEle.addEventListener('click', (e) => {
        return fileEle.click();
      });

    });
  }
}

document.addEventListener('turbolinks:load', () => {
  imageUploader('.js-featured-image-upload');
  imageUploader('.js-nested-image-upload');

  $('body').on('cocoon:after-insert', function(event, insertedItem) {
    // We want to add the image upload only on the newest appended elements.
    if (document.querySelector('.js-featured-image-upload') !== null) {
      insertedItem[0].classList.add('ts-' + Date.now());
      let appendedClass = '.' + insertedItem[0].className.replace(/\s+/g, '.');
      imageUploader(appendedClass + ' .js-featured-image-upload');
    }

  });
  
});


