'use strict'
import { Collapse } from 'bootstrap'
const urlParser = require('query-string')

const collapseOnUrl = (queryString = location.search) => {
  const parsed = new urlParser.parse(queryString, {arrayFormat: 'bracket'})
  if (parsed.collapse) {
    const collapseEle = document.getElementById(parsed.collapse)
    if (collapseEle) {
      // Close related collapses
      collapseSiblings(collapseEle)

      setTimeout(function() {
        // Show the targeted collapse w/o delay
        collapseEle.classList.add('show')
        // Update Bootstrap
        let collapseInt = Collapse.getOrCreateInstance(collapseEle, {
          toggle: false
        })
        collapseInt.show()
      }, (5))
    }
  }

  // Collapse other collapses in a shared accordian
  // https://getbootstrap.com/docs/5.0/components/accordion/
  function collapseSiblings(ele) {
    const eleAccordian = document.querySelector(ele.dataset.parent)
    if (eleAccordian) {
      eleAccordian.querySelectorAll('.collapse').forEach(function(element) {
        element.classList.remove('show')
      })
    }
  }

}

export { collapseOnUrl }

document.addEventListener('turbolinks:load', () => {
  if (location.search.indexOf('collapse=' > 0)) {
    collapseOnUrl(location.search)
  }
})