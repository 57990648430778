'use strict'
import ax from 'axiosInit.js'
import { Collapse } from 'bootstrap'
import sendAirbrakeError from 'sendAirbrakeError.js'
const Url = require('url-parse')

export default function getOembedSource (selector) {
  if (document.querySelector(selector) !== null) {
    let eles = document.querySelectorAll(selector)
    Array.prototype.forEach.call(eles, function(ele){
      const urlInput = ele.querySelector('.js-url-field')
      const oembedInput = ele.querySelector('.js-oembed-data-field')
      const invalidEle = ele.querySelector('.invalid-tooltip')
      const previewEle = ele.querySelector('.js-video-preview')
      const hintEle = ele.parentNode.querySelector('.form-text')
      const collapseEle = Collapse.getOrCreateInstance(ele.querySelector('.collapse'), {
        toggle: false
      })

      const oembedUrl = urlInput.dataset.url
      const defaultInvalidMsg = invalidEle.innerText

      function fetchOembed(event) {
        invalidEle.innerText = defaultInvalidMsg
        urlInput.classList.remove('is-invalid')
        urlInput.classList.remove('is-valid')
        urlInput.classList.add('status')
        if (urlInput.validity.valid === true) {
          ax.post(oembedUrl, {
            url: urlInput.value
          })
          .then(function (response) {
            urlInput.classList.add('is-valid')
            oembedInput.value = JSON.stringify(response.data)
            previewEle.innerHTML = response.data.html
            collapseEle.show()
          })
          .catch(function (error) {
            if (error.response) {
              urlInput.classList.add('is-invalid')
              if (error.response.data.error) {
                invalidEle.innerText = error.response.data.error
              } else {
                invalidEle.innerText = "There was an error fetching the video from YouTube."
              }
            }
            oembedInput.value = JSON.stringify({})
            sendAirbrakeError(error)
          })
          .then(function () {
            urlInput.classList.remove('status')
          })

        } else {
          urlInput.classList.add('is-invalid')
        }
      }

      if (urlInput.value) {
        fetchOembed()
      }
      
      urlInput.addEventListener('change', () => {
        fetchOembed()
      })
    })

  }
}

document.addEventListener('turbolinks:load', () => {
  getOembedSource('.js-oembed-async');
})