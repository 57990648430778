/* eslint no-console:0 */
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue';
Vue.use(TurbolinksAdapter)

// Components
import Payment from '../components/Payment.vue';

const Payments = (selector) => {
  const eles = document.querySelectorAll(selector)

  Array.prototype.forEach.call(eles, function(ele){
    let data = ele.dataset;
    new Vue({ 
      el: ele,
      render: h => h(Payment, {
        props: {
          customer_default_source_url: data.customerDefaultSourceUrl,
          customer_update_payment_info_url: data.customerUpdatePaymentInfoUrl
        }
      })
    })
  })
}

export { Payments }

document.addEventListener('turbolinks:load', () => {
  Payments('.js-payment')
})