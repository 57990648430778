'use strict';
const gaEventOnClick = () => {
  const trackedEles = document.querySelectorAll('.js-analytics-track-data');
  Array.prototype.forEach.call(trackedEles, function(ele){
    ele.addEventListener('click', function(event){
      const eleData = ele.dataset;
      const category = eleData.gacategory != undefined ? eleData.gacategory : '';
      const action = eleData.gaaction != undefined ? eleData.gaaction : '';
      const label = eleData.galabel != undefined ? eleData.galabel : '';
      ga('send', 'event', category, action, label);
    });
  });
}

export default { gaEventOnClick };

document.addEventListener('turbolinks:load', () => {
  if (typeof ga !== 'undefined' && ga !== null) {
    gaEventOnClick();
  }
});