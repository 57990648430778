require('es6-object-assign').polyfill()
import cleanObject from 'cleanObject.js'

export default function joinModalCookie (obj) {
  obj = cleanObject(obj)
  if (Cookies.get('join_setting') != null) {
    obj = Object.assign(Cookies.getJSON('join_setting'), obj)
  }
  Cookies.set('join_setting', obj, { expires: 1825 });
  
};