import geolocateUser from 'geolocateUser'
import { Modal } from 'bootstrap'

// const Cookies = require('js-cookie'); uncomment once this is removed from the asset pipeline
export default function euGdprModal( modalId = 'eu-gdpr') {
  if (!document.getElementById(modalId)) { return }

  function showGDPRModal(countryCode) {
    const modalEle = document.getElementById(modalId)
    const modalInt = Modal.getOrCreateInstance(modalEle, {
      backdrop: 'static'
    })
    modalEle.addEventListener('hidden.bs.modal', function (e) {
      Cookies.set('eu_gdpr', countryCode, { expires: 1825 })
      ga('send', 'event', 'GDPR Modal', 'accept', countryCode)
    })
    modalInt.show()
  }

  geolocateUser(function (result) {
    if (!result) { return }

    let parsedResponse = JSON.parse(result)
    if (parsedResponse.location.is_eu) {
      showGDPRModal(parsedResponse.country_code)
    } else {
      Cookies.set('eu_gdpr', parsedResponse.country_code, { expires: 1825 })
      ga('send', 'event', 'GDPR Modal', 'na', parsedResponse.country_code)
    }
  });
};

window.addEventListener("load", function(event) {
  // First check for the cookie & that we arent in dev to save API use
  if (Cookies.get('eu_gdpr') == null && process.env.NODE_ENV != 'development') {
    euGdprModal()
  }
})




