<template>
  <div :class="deckClasses()">
    <h3 v-html="title"></h3>
    <p>{{options.sub_header}}</p>
    <div v-if="options.empty_filter" v-html="options.well"></div>
    <div v-if="!emptyUsers()" class="row g-5" :class="colClass()">
      <div v-for="user in users" :key="user.id" class="col">
        <user-card :user="user"></user-card>
      </div>
    </div>
    <div v-if="!emptyState()" v-html="footer"></div>
    <hr class="spacer">
  </div>
</template>

<script>
  import sendAirbrakeError from '../javascripts/sendAirbrakeError.js'
  const urlParser = require('query-string')
  
  export default {
    components: {
      UserCard: () => import('UserCard.vue'),
      Spinner: () => import('Spinner.vue')
    },
    props: {
      options: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      users: [],
      footerUrl: '',
      searchQuery: '',
      perPage: 4,
      loaded: false,
      minResult: 2,
      title: '',
      footer: ''
    }),
    methods: {
      updateMinResult (){
       if(this.options.min_result != undefined){
          this.minResult = this.options.min_result;
        }
      },
      emptyState (){
        if (this.users == undefined){ return true; }
        return !this.options.empty_filter && this.emptyUsers();
      },
      emptyUsers (){
        return this.users != undefined && this.users.length < this.minResult;
      },
      colClass (){
        if (this.perPage % 3 == 0){
          return 'row-cols-1 row-cols-md-2 row-cols-lg-3'
        } else if (this.perPage % 2 == 0){
          return 'row-cols-1 row-cols-md-2'
        }
      },
      deckClasses () {
        let deckClasses = '';
        if (this.emptyState()){
          deckClasses += 'hide';
        }
        return deckClasses;
      },
      sendGaEvent (){
        if (this.options.empty_filter){
          ga('send', 'event', 'Suggested Connection Widget', 'user_missing_filters', this.searchQuery);
        } else if(this.users == undefined || this.users.length < this.minResult){
          ga('send', 'event', 'Suggested Connection Widget', 'hidden', this.searchQuery);
        } else {
          ga('send', 'event', 'Suggested Connection Widget', 'shown', this.searchQuery);
        }
      },
      setProps (){
        const parsedQuery = urlParser.parse(this.options.url.split('?')[1], {arrayFormat: 'index'});
        this.searchQuery = parsedQuery.search_query;
        this.perPage = parsedQuery.per_page;
        this.title = this.options.title;
        this.footer = this.options.footer;
      },
      updateData (response){
        this.users = response.users
        this.loaded = true
        this.sendGaEvent();
        if (response.refined_title != undefined){
          this.title = response.refined_title;
        }
        if (response.refined_footer != undefined){
          this.footer = response.refined_footer;
        }
      }
    },
    mounted () {
    },
    created () {
      this.setProps();
      this.updateMinResult();
      if (!this.options.empty_filter){
        const self = this;
        const xhr = new XMLHttpRequest()
        xhr.open('GET', this.options.url);
        xhr.onload = function () {
          if (xhr.status == 200) {
            const response = JSON.parse(xhr.responseText);
            self.updateData(response);
          } else {
            sendAirbrakeError(xhr.status);
          }
        }
        xhr.send()
      } else {
        this.sendGaEvent();
      }
    }
  }
</script>