'use strict'
import '@popperjs/core'
import { Popover } from 'bootstrap'

export default function bsPopover (selector = '[data-bs-toggle="popover"]') {
  const popoverTriggerEles = [].slice.call(document.querySelectorAll(selector))
  const popoverList = popoverTriggerEles.map(function (popoverTriggerEle) {
    if (Popover.getInstance(popoverTriggerEle)) {
      return
    } else {
      new Popover(popoverTriggerEle, {
        html: true,
        placement: 'auto'
      })    
    }
  })
}

window.addEventListener('turbolinks:load', () => {
  bsPopover()
})