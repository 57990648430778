export default function simulateAddFilterClick(filterType) {
  const addBtnSelector = `js-virtual-add-${filterType}`;
  const virtualAddFilterBtn = document.getElementById(addBtnSelector);
  if (virtualAddFilterBtn != null) {
    virtualAddFilterBtn.addEventListener('click', () => {
      let newGoalForm = document.getElementById(`profile-${filterType}-new`);
      if (newGoalForm != null){
        newGoalForm.style.display = 'block';
      } else {
        document.getElementById(`js-add-${filterType}`).click();
        document.getElementById(`profile-${filterType}-new`).classList.remove('hide');
      }
    });
  }
}

document.addEventListener("turbolinks:load", () => {
  $.fn.simulateAddFilterClick = simulateAddFilterClick;
  $.fn.simulateAddFilterClick('goal');
  $.fn.simulateAddFilterClick('expertise');
})
