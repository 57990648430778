//
// wrapper around axios to include Rails CSRF token & headers
// use 'ax' instead of 'axios'
//

import axios from 'axios'

const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
const ax = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': token.content,
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json'
    }
  }
})

export default ax