'use strict'
// polyfill
import 'nodelist-foreach-polyfill'
import 'intersection-observer'
import eleInViewport from 'eleInViewport.js'
import getTransitionDuration from 'getTransitionDuration.js'
import setTransitionDuration from 'setTransitionDuration.js'

export default function cardDeckCarousel (element) {
  const ele = element.querySelector('.card-deck-horizontal')
  const time = 700 // from browser's scroll behavior: smooth duration.
  setTransitionDuration(ele, 400)
  const transDuration = getTransitionDuration(ele)
  const nextBtn = element.querySelector('.carousel-control-next')
  const prevBtn = element.querySelector('.carousel-control-prev')
  const viewWidth = window.innerWidth || document.documentElement.clientWidth
  let ticking = false

  if(ele) {
    let eleScrollLeft = ele.scrollLeft
    let eleOffsetWidth = ele.offsetWidth
    let eleScrollWidth = ele.scrollWidth
    let eleScrollLeftMax = (eleScrollWidth - eleOffsetWidth)

    function transistionIn() {
      ele.classList.remove('translate-x-25')
      setTimeout(function() {
        element.classList.remove('overflow-hidden')
      }, (transDuration))
    }

    function updateButtons(ele, eleScrollLeftMax) {
      let eleScrollLeft = Math.floor(ele.scrollLeft)
      if (eleScrollLeft == 0) {
        prevBtn.disabled = true
      } else {
        prevBtn.disabled = false
      }

      if ((eleScrollLeft == eleScrollLeftMax) || eleScrollLeftMax == 0 ) {
        nextBtn.disabled = true
      } else {
        nextBtn.disabled = false
      }
    }

    function initCardDeckCarousel(ele, timer) {
      function updateCarouselDimensions() {
        eleScrollLeft = ele.scrollLeft
        eleScrollWidth = ele.scrollWidth
        eleOffsetWidth = ele.offsetWidth
        eleScrollLeftMax = eleScrollWidth - eleOffsetWidth;
      }

      prevBtn.addEventListener('click',
        function () {
          updateCarouselDimensions(); //in case window resized,
          if (ele.scrollLeft > 0) {
            ele.scrollLeft = (eleScrollLeft - eleOffsetWidth)
          }
        }
      );

      nextBtn.addEventListener("click",
        function () {
          updateCarouselDimensions(); //in case window resized
          if (ele.scrollLeft < eleScrollLeftMax) {
            ele.scrollLeft = (eleScrollLeft + eleOffsetWidth)
          }
        }
      );
    }

    initCardDeckCarousel(ele)
    updateButtons(ele, eleScrollLeftMax)

    // Slides in the cards when in viewport
    if(eleInViewport(element)) {
      transistionIn()
    }
    
    // Slides in the cards when it goes into scroll
    let observer = new IntersectionObserver((entries, observer) => { 
      entries.forEach(entry => {
        if(entry.intersectionRatio!=0 && ele.scrollLeft == 0){
          transistionIn()
          observer.unobserve(element);
        }
      })
    }, {rootMargin: '32px 0px 0px 0px'})
    observer.observe(element)

    // Add a scroll event if the deck & viewport is wide enough
    if ((eleScrollWidth > eleOffsetWidth) && (viewWidth > eleOffsetWidth)) {
      ele.addEventListener('scroll', function(e) {
        if (!ticking) {
          window.requestAnimationFrame(function() {
            updateButtons(ele, eleScrollLeftMax)
            ticking = false
          })
          ticking = true
        }
      })
    }

  }
}