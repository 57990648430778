'use strict'
import 'nodelist-foreach-polyfill'

export default function toggleCheckboxes (selector = 'input.js-toggle-checkboxes') {
  const toggleCheckboxesEles = document.querySelectorAll(selector)

  function updateChecked(eles, checked) {
    eles.forEach((ele) => {
      ele.checked = checked
    })
  }

  function updateCount(eles) {
    let checkedCount = 0;
    eles.forEach((ele) => {
      if(ele.checked) {
        checkedCount++;
      }
    })
    return checkedCount
  }

  function updateCountEle(eles, count) {
    eles.forEach((ele) => {
      ele.innerText = count
    })
  }


  toggleCheckboxesEles.forEach((ele) => {
    const targetSelector = ele.getAttribute('data-target')
    const targetEles = document.querySelectorAll(targetSelector)

    const countSelector = ele.getAttribute('data-count')
    const countEles = document.querySelectorAll(countSelector)

    updateCountEle(countEles, updateCount(targetEles))
    ele.addEventListener('change', (e) => {
      const checked = ele.checked
      updateChecked(targetEles, checked)
      updateCountEle(countEles, updateCount(targetEles))
    })

    targetEles.forEach((targetEle) => {
      targetEle.addEventListener('change', (e) => {
        updateCountEle(countEles, updateCount(targetEles))
      })
    })
  })
}

document.addEventListener('turbolinks:load', () => {
  toggleCheckboxes()
})