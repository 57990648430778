<template>
  <fieldset class="form-group mb-0" v-bind:class="{ fade : !loaded, in : !loaded }">
    <legend>Your Payment Information</legend>
    <div v-if="!isEmpty(default_source)" class="form-group default-payment">
      <label>Paying with&hellip;</label>
      <div class="d-flex form-control read-only form-control-default-source"
        v-bind:class="{ 'is-valid focus' : default_source_highlight }">
        <div class="flex-grow-1 flex-row flex-nowrap d-flex text-truncate">
          <span class="cc my-auto" v-bind:class="default_source.brand.toLowerCase()"></span>
          {{default_source.brand}} ending in {{default_source.last4}}
        </div>
        <div class="flex-shrink-1">
          {{default_source.exp_month}}/{{default_source.exp_year}}
        </div>
      </div><!-- /.d-inline-flex -->
    </div>
    <div class="form-group">
      <button aria-controls="add-new-card" 
        data-bs-toggle="collapse" 
        data-bs-target="#add-new-card" 
        class="collapse-button"
        type="button"
        v-bind:class="{ 'collapsed' : !isEmpty(default_source) }">
        Add New Card
      </button>
      <div id="add-new-card" 
        aria-labelledby="add-new-card" 
        class="position-relative collapse"
        v-bind:class="{ 'show' : isEmpty(default_source) }">
        <StripeElements
          :stripe-key="stripeKey"
          :instance-options="instanceOptions"
          :elements-options="elementsOptions"
          #default="{ elements }"
          ref="elms"
          class="position-relative d-flex flex-row">
          <StripeElement
            type="card"
            :elements="elements"
            :options="cardOptions"
            @change="checkStripeElement"
            class="w-100"
            v-bind:disabled="submitting == true"
            ref="card"/>
            <button class="btn btn-outline-primary flex-shrink-1 text-nowrap fade hide btn-stripe-element js-payment-update-btn"
              @click="sendToken();"
              data-gaaction="submitted" 
              data-gacategory="payment"
              data-galabel="inline"
              id="update-btn"
              ref="update_btn"
              role="button"
              type="button"
              v-bind:disabled="submitting || token_sent"
              v-bind:class="{'show' : stripe_token, 'btn-status' : submitting }">Save</button>
            <div class="invalid-tooltip fade"
              v-bind:class="{ 'show' : stripe_error }">{{ stripe_error }}</div>
            <div class="valid-tooltip fade"
              v-bind:class="{ 'show' : stripe_success }">{{ stripe_success }}</div>
        </StripeElements>
        <small class="form-text text-muted d-inline-block">We'll save this as your default payment method</small>
      </div><!-- /.collapse -->
    </div><!-- /.form-group -->
  </fieldset><!-- /.form-group -->
</template>

<script>
  import ax from '../javascripts/axiosInit.js'
  import gaTrackEvents from '../javascripts/gaTrackEvents.js'
  import sendAirbrakeError from '../javascripts/sendAirbrakeError.js'
  import stripeConfig from '../javascripts/stripeConfig.js.erb'
  import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
  const isObjectEmpty = require('is-object-empty')
  const stripe = stripeConfig()

  export default {
    name: "Payment",
    components: {
      StripeElements,
      StripeElement
    },
    props: {
      customer_default_source_url: {
        type: String,
        required: true
      },
      customer_update_payment_info_url: {
        type: String,
        required: true
      }
    },
    data: () => ({
      loaded: false,
      default_source: undefined,
      default_source_highlight: false,
      stripe_error: undefined,
      stripe_success: undefined,
      stripe_token: undefined,
      submitting: false,
      stripeKey: stripe._apiKey,
      source_valid: false,
      token_sent: false,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
        fonts: [
          { 
            cssSrc: 'https://use.typekit.net/xdn0mgc.css'
          }
        ]
      },
      cardOptions: {
        hideIcon: false,
        hidePostalCode: true,
        iconStyle: 'solid',
        style: {
          base: {
            iconColor: '#d9dee2',
            color: '#495057',
            fontFamily: '"nimbus-sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            ':-webkit-autofill': {
              color: '#495057',
              backgroundColor: 'rgb(232, 240, 254)'
            },
            '::placeholder': {
              color: '#687178'
            }
          },
          empty: {
            color: '#687178'
          },
          invalid: {
            iconColor: '#d12d10',
            color: '#495057'
          },
          valid: {
            iconColor: '#066635',
            color: '#495057'
          }
        },
        classes: {
          base: 'form-control form-control-stripe-element',
          complete: 'is-valid is-complete',
          empty: 'text-placeholder',
          focus: 'focus',
          invalid: 'is-invalid',
          webkitAutofill: 'is-valid'
        }
      }
    }),
    methods: {
      isEmpty(obj) {
        isObjectEmpty(obj)
        for(var key in obj) {
          if(obj.hasOwnProperty(key))
            return false
        }
        return true
      },
      setUpdateFocus: function () {
        this.$refs.update_btn.focus()
      },
      reloadWindowForPastDue: function () {
        // Check for the past due modal
        if (document.getElementById('past-due-lock')) {
          return location.replace(location.protocol + '//' + location.host + location.pathname)
        } else {
          return
        }
      },
      showError: function (message) {
        const self = this    
        const cardComponent = self.$refs.card
        // Get stripe element
        const cardElementParent = cardComponent.stripeElement
        // In Vue _parent is the element a componant 'mounts' to
        cardElementParent._parent.classList.remove('is-valid')
        cardElementParent._parent.classList.add('is-invalid')
        self.stripe_success = undefined
        return self.stripe_error = message
      },
      checkStripeElement: function () {
        const self = this
        self.token_sent = false
        self.stripe_token = undefined
        const groupComponent = this.$refs.elms
        const cardComponent = this.$refs.card
        // Get stripe element
        const cardElement = cardComponent.stripeElement
        // Access instance methods, e.g. createToken()
        groupComponent.instance.createToken(cardElement).then(result => {
          if (result.error) {
            self.stripe_success = undefined
            self.stripe_error = result.error.message
          } else if (result.token) {
            self.stripe_success = 'Looks good! Save your card to continue.'
            self.stripe_error = undefined
            self.stripe_token = result.token
            setTimeout(function() {
              self.setUpdateFocus()
            }, (2))
          } else {
            self.stripe_error = undefined
            self.stripe_success = undefined
          }
        })
      },
      sendToken: function () {
        const self = this
        if (self.stripe_token) {
          self.token_sent = true
          setTimeout(function() {
            self.updatePaymentInfo()
          }, (10))
        }
      },
      updateSourceValid: function (value) {
        const self = this
        self.source_valid = value
        this.$emit('source_valid', value)
      },
      updatePaymentInfo: function() {
        const self = this      
        self.submitting = true
        self.stripe_errors = undefined

        // ax = axios
        ax.post(self.customer_update_payment_info_url, {
            card_token: self.stripe_token.id
          })
          .then(function (response) {
            self.stripe_success = response.data.messages
            self.getDefaultSource()
            self.updateSourceValid(true)
            self.default_source_highlight = true
            setTimeout(function() {
              self.default_source_highlight = false
            }, (750))
            self.reloadWindowForPastDue()
            if (typeof ga !== 'undefined' && ga !== null) {
              ga('send', 'event', 'payment', 'update', 'success')
            }
   
          })
          .catch(function (error) {
            // handle error
            self.showError('There was a problem updating your payment information')
            if (typeof ga !== 'undefined' && ga !== null) {
              ga('send', 'event', 'payment', 'update', 'fail')
            }
            sendAirbrakeError(error)
          })
          .then(function () {
            self.submitting = false
            self.updateSourceValid(true)
          });
      },
      getDefaultSource: function() {
        const self = this      
        self.submitting = true
        // ax = axios
        ax.get(self.customer_default_source_url)
          .then(function (response) {
            self.default_source = response.data
          })
          .catch(function (error) {
            // handle error
            sendAirbrakeError(error)
          })
          .then(function () {
            self.submitting = false
          });
      },
      setElementSize: function() {
        const self = this
        // Smaller text for mobile
        if (window.innerWidth <= 640) {
          self.cardOptions.hideIcon = true
          self.cardOptions.style.base.fontSize = '14px'
        } else {
          self.cardOptions.hideIcon = false
        }
        
      }
    },
    created: function () {
      const self = this
      ax.get(self.customer_default_source_url)
        .then(function (response) {
          self.default_source = response.data
          self.updateSourceValid(true)
        })
        .catch(function (error) {
          // handle error
        })
        .then(function () {
          self.loaded = true
        });

    },
    mounted: function () {
      const self = this
      self.setElementSize()
    }
  }
</script>