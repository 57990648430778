import oneTapConfig from 'oneTapConfig.js.erb'
import ax from 'axiosInit.js'
import sendAirbrakeError from 'sendAirbrakeError.js'
import { Modal } from 'bootstrap'

export default function googleOneTap () {
    const head = document.getElementsByTagName('head')[0];
    const resource = document.createElement("script");
    const joinModalEle = document.getElementById('join')
    resource.src = "https://accounts.google.com/gsi/client";
    head.appendChild(resource);

    function getOneTapWidth(ele) {
        return ele.offsetWidth
    }

    window.onGoogleLibraryLoad  = () => {
        const oneTap = oneTapConfig();
        if (google) {
            google.accounts.id.initialize({
                client_id: oneTap.client_id,
                context: "use",
                ux_mode: "redirect",
                login_uri: oneTap.login_uri,
                auto_select: false,
                callback: function (response) {
                    let token = response['credential'];
                    ax.post(oneTap.google_onetap_path, {
                        'credential': token,
                        'ref': window.location.pathname
                    }).then(function (response) {
                        window.location.href = response.data['redirect_path'];
                    }).catch(function (error) {
                        // handle error
                        sendAirbrakeError(error)
                    }).then(function () {
                        // This always executes
                    });
                }
            });
        }
        //handle one tap prompt/response
        let currentUser = false;
        if (document.getElementById('js_current_user_data') || document.getElementById('admin_panel')) {
            // do nothing
            return;
        } else {
            google.accounts.id.prompt((notification) => {
                //more specific options inside the one tap popup
            });
        }
        //add button to login page
        const one_tap_button = document.getElementById('js-google-one-tap-signin');
        const js_login_with_email_input = document.getElementById('js-login-with-email-input')
        if (js_login_with_email_input) {
            google.accounts.id.renderButton(one_tap_button, {
                type: "standard",
                size: "large",
                shape: "rectangular",
                theme: "filled_blue",
                text: "signin_with",
                logo_alignment: "left",
                width: getOneTapWidth(js_login_with_email_input)
            });
        }
        //Join Modal
        const one_tap_modal_button = document.getElementById('js-google-one-tap-signup');
        const joinModalEmailBtnEle = document.querySelector('.js-google-one-tap-width')
        joinModalEle.addEventListener('shown.bs.modal', function(e) {
            setTimeout(function() {
                google.accounts.id.renderButton(one_tap_modal_button, {
                    type: "standard",
                    size: "large",
                    shape: "rectangular",
                    theme: "filled_blue",
                    text: "signup_with",
                    logo_alignment: "left",
                    width: getOneTapWidth(joinModalEmailBtnEle)
                });
            }, 10);
        });
    };
}

document.addEventListener('turbolinks:load', () => {
    googleOneTap();
});
