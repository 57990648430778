'use strict';

const gaTrackPageview = () => {
  if (typeof ga !== 'undefined' && ga !== null) {
    ga('set', 'location', location.href.split('#')[0]);
    ga('send', 'pageview', window.location.pathname);
  }
}

export { gaTrackPageview };

document.addEventListener('turbolinks:load', () => {
   gaTrackPageview();
});