'use strict'
import Countable from 'countable'

export default function validateInputLength (selector) {
  function updateTip (ele, maxLength, count) {
    let validTip = ele.parentElement.querySelector('.valid-tooltip > span')
    let remaing = maxLength - count
    validTip.innerText = remaing + ' characters remaining'
  }

  function appendTip (ele) {
    let validTip = document.createElement('div');
    validTip.classList.add('valid-tooltip')
    validTip.appendChild(document.createElement('span'))
    ele.after(validTip)
  }

  function validateInput (ele, maxLength, counter) {
    
    updateTip(ele, maxLength, counter.all)
    if (counter.all == 0) {
      ele.classList.remove('is-valid')
    } else {
      ele.classList.remove('is-valid', 'is-invalid')
      if (counter.count > maxLength) {
        ele.classList.add('is-invalid')
      } else {
        ele.classList.add('is-valid')
      }
    }
  }

  if (document.querySelector(selector) !== null) {
    let inputs = document.querySelectorAll(selector)
    inputs.forEach(function (ele) {
      let maxLength = parseInt(ele.getAttribute('maxlength'))
      appendTip(ele)
      Countable.on(ele, counter => validateInput(ele, maxLength, counter))
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  validateInputLength('.js-validate-length')
})