import filterDropDown from "./filterDropDown.js";
import filterCollapse from "./filterCollapse.js";
import dropDownSort from "./dropDownSort.js";
import moreOptionsDropDown from "./moreOptionsDropDown.js"
import addNameToInput from "./addNameToInput.js"

document.addEventListener('turbolinks:load', function() {
  filterDropDown(".js-filter-dropdown");
  filterCollapse(".js-filter-collapsible");
  dropDownSort(".js-dropdown-sort");
  moreOptionsDropDown(".js-dropdown-button", "js-more-options-dropdown");
  addNameToInput(".js-add-name-to-input");
});