'use strict'
import autosize from 'autosize'
import { Collapse } from 'bootstrap'

export default function autosizeTextarea(selector = 'textarea.js-autosize-textarea') {
  if (document.querySelector(selector) !== null) {
    let inputs = document.querySelectorAll(selector)
    inputs.forEach((input) => {
      let parentCollapseEle = input.closest('.collapse')
      if (parentCollapseEle) {
        if (parentCollapseEle.classList.contains('show')) {
          input.removeAttribute('style')
          autosize(input)
        }
      } else {
        autosize(input)
      }      
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  autosizeTextarea('textarea.js-autosize-textarea')

  
  const inputCollapseEles = document.querySelectorAll('.collapse textarea.js-autosize-textarea')
  if (inputCollapseEles) {
    const collapseEles = document.querySelectorAll('.collapse')
    collapseEles.forEach((collapseEle) => {
      collapseEle.addEventListener('shown.bs.collapse', (e) => {
        setTimeout(function() {
          autosizeTextarea('.collapse.show textarea.js-autosize-textarea')
        }, (2))   
      })
    })
  }
})