'use strict'

export default function placeholderStyledSelect(tag) {
// export default function placeholderStyledSelect = (tag) => {
  const eles = document.getElementsByTagName(tag)
  const placeClass = 'text-placeholder'

  function inputValidated(ele) {
    if (ele.classList.contains('is-valid') || ele.classList.contains('is-invalid') ) {
      return true
    } else {
      return false
    }
  }

  function formValidated(ele) {
    if (ele.form && ele.form.classList.contains('was-validated')) {
      return true
    } else {
      return false
    }
  }


  Array.prototype.forEach.call(eles, function(ele) {
    if (inputValidated(ele) || formValidated(ele)) {
      ele.classList.remove(placeClass)
    } else {
      if (!ele.value) {
        ele.classList.add(placeClass)
      } else {
        ele.classList.remove(placeClass)
      }
    }

    ele.addEventListener('change', (e) => {
      e.target.classList.remove(placeClass)
      if (!inputValidated(ele) && !formValidated(ele) && !e.target.value) {
        e.target.classList.add(placeClass)
      }
    })
  })
}

document.addEventListener('turbolinks:load', () => {
  placeholderStyledSelect('select')

  $('body').on('cocoon:after-insert', (e, added_ele) => {
    placeholderStyledSelect('select')
  })
})