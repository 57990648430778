'use strict'
import { Modal } from 'bootstrap'

export default function pastDueModal(modalId = 'past-due-lock') {
  if (document.getElementById(modalId) && (window.location.href.indexOf('/account') === -1)) {
    const modalEle = document.getElementById(modalId)
    const modalBtnCloseEle = modalEle.querySelector('.modal-header .btn-close')
    const modalInt = Modal.getOrCreateInstance(modalEle, {
      backdrop: 'static',
      keyboard: false
    })
    modalBtnCloseEle.remove()
    modalInt.show()
  }
}

document.addEventListener('turbolinks:load', () => {
  pastDueModal()
})