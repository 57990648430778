'use strict';
const fontFaceSetEvent = () => {
  if (typeof document.fonts !== "undefined") { // https://caniuse.com/font-loading.ready
    async function fontFacesLoaded() {
      let ready = await document.fonts.ready
      document.documentElement.classList.add('font-faces-loaded')
    }
    fontFacesLoaded();
  }
}

export { fontFaceSetEvent }

fontFaceSetEvent()