'use strict';
export default function validateFormOnSubmit (selector, callback = false) {
  if (document.querySelector(selector) !== null) {
    let formsEles = document.querySelectorAll( selector );
    let validation = Array.prototype.filter.call(formsEles, function(form) {
      form.setAttribute('novalidate', true);
      let hasImage = form.querySelectorAll('.js-featured-image-upload').length > 0;

      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {

          // This changes the form submit to jQuery b/c Rails JS
          // cant process image data
          if (hasImage == true) {
            event.preventDefault();
            event.stopPropagation();
            let formData = new FormData($(this)[0]);

            $.ajax({
              url: form.action,
              type: 'POST',
              data: formData,
              async: false,
              success: function (data) {

              },
              cache: false,
              contentType: false,
              processData: false
            });
            return false;
          }

        }

        form.classList.add('was-validated');
        
      }, false);
    });
  }
}

document.addEventListener('turbolinks:load', () => {
  validateFormOnSubmit('.js-validate-on-submit')
})