'use strict'
import ax from 'axiosInit.js'
import loadExternalScript from 'loadExternalScript.js'
import recaptchaConfig from 'recaptchaConfig.js.erb'
import routesConfig from 'routesConfig.js.erb'
import sendAirbrakeError from 'sendAirbrakeError.js'
import { Modal } from 'bootstrap'

export default function recaptchaVerify () {
  if (typeof grecaptcha !== 'undefined') {
    const routes = routesConfig()
    const recaptchaPath = routes['recaptcha']
    const recaptchaKey = recaptchaConfig()
    grecaptcha.ready(function () {
      grecaptcha.execute(recaptchaKey, {
        action: 'verify'
      }).then(function (token) {
        ax.post(recaptchaPath, {
          'g-recaptcha-response-data': token
        }).then(function (response) {
          const data = response.data
          const recaptchaSubmitEle = document.querySelector('.js-recaptcha-submit')
          const recaptchaCheckboxEle = document.querySelector('.js-recaptcha-v2')
          if (data.recaptcha_success) {
            recaptchaSubmitEle.disabled = false
          } else {
            recaptchaSubmitEle.classList.add('btn-status')
            recaptchaSubmitEle.innerText = 'Please Verify Above'
            //put v2 into any form that requires recaptcha
            //put recaptcha into forms
            recaptchaCheckboxEle.innerHTML = data.v2checkbox_html
            recaptchaCheckboxEle.classList.remove('d-none')
            loadExternalScript('https://www.recaptcha.net/recaptcha/api.js')
              .then(() => {
                window.captchaChange = function () {
                  let gresponse = document.getElementById('g-recaptcha-response')
                  if (gresponse.value !== '') {
                    recaptchaSubmitEle.innerText = 'Continue'
                    recaptchaSubmitEle.disabled = false
                  } else {
                    sendAirbrakeError('[recaptcha][v2] captchaChange fail')
                    recaptchaSubmitEle.disabled = true
                  }
                }
              })
              .catch(() => {
                sendAirbrakeError('[recaptcha][v2] external script fail')
              })

          }
        }).catch(function (error) {
                    // handle error
          sendAirbrakeError(error)
        }).then(function () {
                    // This always executes
        })
      })
    })
  }
}

document.addEventListener('turbolinks:load', () => {

  //handle recaptcha verify
  if (!document.getElementById('js_current_user_data') && document.getElementById('join')) {
    let joinModalEle = document.getElementById('join')
    let joinModalInt = Modal.getOrCreateInstance(joinModalEle)
    let recaptchaSubmitEle = document.querySelector('.js-recaptcha-submit')
    joinModalEle.addEventListener('show.bs.modal', function (e) {
      if (recaptchaSubmitEle) {
        recaptchaVerify()
      }
    })
  }
})
