'use strict'
import sendAirbrakeError from 'sendAirbrakeError.js'

export default function loadExternalScript (scriptUrl = false) {
  if (scriptUrl) {
    const script = document.createElement('script')
    script.src = scriptUrl
    document.head.appendChild(script)
    return new Promise((res, rej) => {
      script.onload = function() {
        res()
      }
      script.onerror = function () {
        rej()
      }
    })
  }
}

// loadExternalScript('http://foo.com/bar.js')
//   .then(() => {
//     console.log('Script loaded!')
//   })
//   .catch(() => {
//     console.error('Handle this error')
//   })