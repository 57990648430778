'use strict';
export default function submitFormOnChange (selector) {
  const formEle = document.querySelectorAll(selector);
  if ( formEle.length > 1 ) {
    formEle.forEach( function(ele) {
      const eleForm = ele.form;
      ele.addEventListener("change", function(e) {
        eleForm.submit();
      }, false);
    });
  };
};

document.addEventListener('turbolinks:load', () => {
  submitFormOnChange('.js-submit-on-change');
});