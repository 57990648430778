import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
import filters from 'filters'
Vue.use(filters)
Vue.use(TurbolinksAdapter)
import { Modal } from 'bootstrap'
import resetModal from 'resetModal.js'

import EventsReports from '../components/EventsReports.vue'

document.addEventListener('turbolinks:load', () => {
  const modalEle = document.getElementById('finance-table')
  if (modalEle) {
    const modalInt = Modal.getOrCreateInstance(modalEle)
    modalEle.addEventListener('show.bs.modal', function (e) {
      const eventsReportsEle = document.getElementById('vue-events-reports')
      if(eventsReportsEle){
        new Vue({
          el: eventsReportsEle,
          render: h => h(EventsReports, {
            props: {
              eventId: eventsReportsEle.dataset.eventid
            }
          })
        })
      }
    })

  }

  document.addEventListener('turbolinks:before-cache', () => {
    resetModal()
  })
})