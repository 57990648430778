import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue';
import filters from 'filters';
Vue.use(filters)
Vue.use(TurbolinksAdapter)

import EventRegistrationTicket from '../components/EventRegistrationTicket.vue';

const eventRegistrationTickets = (selector) => {
  const eles = document.querySelectorAll(selector)

  Array.prototype.forEach.call(eles, function(ele){
    let data = ele.dataset;
    new Vue({ 
      el: ele,
      render: h => h(EventRegistrationTicket, {
        props: {
          new_event_registration_url: data.newEventRegistrationUrl,
          login_url: data.loginUrl
        }
      })
    })
  })
}

export default eventRegistrationTickets

document.addEventListener('turbolinks:load', () => {
  eventRegistrationTickets('.js-event-registrations-tickets')
})