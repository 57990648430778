import joinModalCookie from 'joinModalCookie.js'
import confettiAnimation from 'confettiAnimation.js'
import resetModal from 'resetModal.js'
import { Modal, Collapse } from 'bootstrap'

const joinModal = new function() {

  const joinModalEle = document.getElementById('join')
  if (joinModalEle) {
    Modal.getOrCreateInstance(joinModalEle)
  }
  const showDelay = 20000
  const urlsForModals = ['articles','chapters','dashboard','member-successes','member-spotlights','events','team','videos','about','join-benefits'];
  const profileGoalsCollapseEle = document.getElementById('profile_goals');
  const successCollapseEle = document.getElementById('join-success');
  const userIdEles = document.querySelectorAll( '.js-form-signup-step input.js-current-user-id' );

  this.showSuccess = function(data) {
    joinModalCookie(data);
    confettiAnimation('.join-modal .js-confetti', 300);
  }

  this.checkEvent = function() {
    const eles = document.querySelectorAll('.js-sticky-event-registration')
    if (document.getElementById('events_show') && document.getElementById('js_current_user_data') && (eles.length > 0)) {
      return false
    } else {
      return true
    }
  }

  this.checkUrl = function() {
    let firstPath = location.pathname.split('/')
    if (document.body.classList.contains('home')) {
      return true
    } else if (urlsForModals.indexOf(firstPath[1]) >= 0) {
      return true
    } else {
      return false
    }
  }

  this.setupModal = function() {
    joinModalEle.addEventListener('show.bs.modal', () => {
      joinModalCookie({ autoShow: false })
    })     
  }

  this.showModal = function(timer = true) {
    let joinCookie = Cookies.getJSON('join_setting')
    let joinModalInt = Modal.getOrCreateInstance(joinModalEle)
    if (timer == true) {
      let joinModalTimer = setTimeout(showModalDelay, showDelay);
      function showModalDelay() {
        if (joinCookie == undefined || (joinCookie != undefined && joinCookie.autoShow)) {
          if (typeof ga !== 'undefined' && ga !== null) {
            ga('send', 'event', 'acquisition', 'timer', 'join'); 
          }
          setTimeout(function() {
            joinModalInt.show()
          }, (5))
        }

      }
    } else {
      setTimeout(function() {
        joinModalInt.show()
      }, (5))
    }

  }

  this.closeModal = function(reload = false) {
    let joinModalInt = Modal.getOrCreateInstance(joinModalEle)
    joinModalEle.addEventListener('hidden.bs.modal', () => {
      resetModal()
    })
    if (reload === true) {
      joinModalEle.addEventListener('hidden.bs.modal', () => {
        location.reload()
      })
    } else {
      joinModalInt.hide()
    }
  }

  this.updateUserIds = function(user_id) {
    if (user_id) {
      userIdEles.forEach(ele => ele.value = user_id);
    }
  }

  this.profileGoalsNeedsUpdate = function(currentUser = false) {
    let returnVal = false;
    if (currentUser) {
      if (currentUser.profile_update_section == 'profile_goals' || currentUser.profile_update_section == 'profile_details' ) {
        returnVal = true;
      }
    }
    return returnVal;
  }

  this.getCurrentUser = function() {
    let currentUser = false;
    if (document.getElementById('js_current_user_data')) {
      currentUser = JSON.parse(document.getElementById('js_current_user_data').textContent);
    }
    return currentUser;
  }

};

document.addEventListener('turbolinks:load', () => {
  let currentUser = joinModal.getCurrentUser();
  let showSignUpModal = false;

  // *****************************************************************
  if (currentUser == false || joinModal.profileGoalsNeedsUpdate(currentUser)) {
    showSignUpModal = joinModal.checkUrl() && joinModal.checkEvent()
  }

  if (showSignUpModal) {
    let joinCookieSetting = Cookies.getJSON('join_setting')
    const joinModalEle = document.getElementById('join');

    if (joinModalEle) {
      joinModalEle.removeAttribute('style');
      if (joinCookieSetting != null && joinCookieSetting.success) {
        return;
      } else {
        joinModal.setupModal();

        // *****************************************************************
        let account_claim = document.getElementById('js-account-claim');
        let claim_status = document.getElementById('js-claim-status');
        if (currentUser && joinModal.profileGoalsNeedsUpdate(currentUser)) {
          joinModalCookie({ autoShow: true });
          joinModal.updateUserIds(currentUser.id);
          let currentStepEle = document.getElementById(currentUser.profile_update_section)
          let collapseInt = Collapse.getOrCreateInstance(currentStepEle, {
            toggle: false
          })
          collapseInt.show()
          if (account_claim != null) {
            let timeout = 20000;
            if (claim_status != null) {
              timeout = 3000;
            }
            setTimeout(function () {
              joinModal.showModal(false);
            }, timeout);
          } else {
            setTimeout(function () {
              joinModal.showModal(false);
            }, 5);
          }
          joinModal.closeModal(false);
        } else {
          if (account_claim != null) {
            setTimeout(function () {
              joinModal.showModal(false);
            }, 500);
          } else {
            setTimeout(function () {
              joinModal.showModal(true);
            }, 5);
            joinModal.closeModal(false);
          }
        }
      }

      // Sign up forms
      const getSignUpStepEles = document.querySelectorAll( '.js-form-signup-step' );
      for (let i = 0; i < getSignUpStepEles.length; i++) {
        let submitEles = getSignUpStepEles[i].querySelectorAll( '.btn.js-profile-submit' )

        $(getSignUpStepEles[i]).on('ajax:before', function(event) {
          submitEles[0].disabled = true
          submitEles[0].classList.add('btn-status')
        }).on('ajax:error', function(event, data, status, xhr) {
          submitEles[0].disabled = false
          submitEles[0].classList.remove('btn-status')
        }).on('ajax:success', function(event, data, status, xhr) {
          if (status == 'success') {
            // update user id inputs if its a new user
            joinModal.updateUserIds(data.id);

            if (data.gaobj) {
              if (typeof ga !== 'undefined' && ga !== null) {
                ga('send', 'event', data.gaobj.category, data.gaobj.action, data.gaobj.label); 
              }
            }
            
            if (data.fbobj) {
              if (typeof fbq !== 'undefined' && fbq !== null) {
                fbq(
                  'trackCustom',
                  data.fbobj.category, data.fbobj.obj
                );
              }
            }

            // Reload the page on
            if (data.reload) {
              location.reload();
            } else {
              let collapseTarget = getSignUpStepEles[i].getAttribute('data-bs-target')
              let collapseStepEle = document.querySelector(collapseTarget)
              setTimeout(function() {
                let collapseStepInt = Collapse.getOrCreateInstance(collapseStepEle, {
                  toggle: false
                })
                collapseStepInt.show()
              }, (5))
              if (getSignUpStepEles[i].getAttribute('data-bs-target') == '#join-success') {
                joinModal.showSuccess({success: true});
              }
            }
          }

        });
      }

    }

  }
});
