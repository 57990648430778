'use strict';
import ax from 'axiosInit.js'
import sendAirbrakeError from 'sendAirbrakeError.js'

const inlineStructuredData = () => {
  const DataEles = document.querySelectorAll('.js-inline-structured-data')
  Array.prototype.forEach.call(DataEles, function(ele){
    const url = ele.dataset.url
    ax.get(url)
    .then(function (response) {
      const script = document.createElement('script')
      script.setAttribute('type', 'application/ld+json')
      script.textContent = JSON.stringify(response.data)
      document.head.appendChild(script)
    })
    .catch(function (error) {
      sendAirbrakeError(error)
    })
    .then(function () {})
  })
}

export { inlineStructuredData }

// Annoying for development
if (process.env.NODE_ENV == 'production') {
  // IDK if Turbolinks loads in a Googlebot visit
  window.addEventListener('load', function () {
    inlineStructuredData()
  })
}