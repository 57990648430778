'use strict'

require('es6-object-assign').polyfill()
export default function sendAirbrakeError(errorMsg, opts = {}) {
  if (typeof airbrake !== 'undefined' && airbrake !== null) {
    const errorObj = {
      error: errorMsg
    }
    
    const currentUserDataEle = document.getElementById('js_current_user_data')
    if (currentUserDataEle) {
      const currentUserData = JSON.parse(currentUserDataEle.textContent)
      if (typeof currentUserData.id !== 'undefined') {
        Object.assign(errorObj, {
          params: { user_id: currentUserData.id }
        })
      }
    }

    airbrake.notify(errorObj)
  }
}