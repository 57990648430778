'use strict'
import 'nodelist-foreach-polyfill'
import { Toast } from 'bootstrap'

export default function bsToast (selector = '.toast') {
  const toastEles = document.querySelectorAll(selector)
  toastEles.forEach((ele) => {
    const toast = Toast.getOrCreateInstance(ele, {
      autohide: false
    })

    if (typeof ga !== 'undefined' && ga !== null) {
      const eleData  = ele.dataset
      const category = eleData.gacategory != undefined ? eleData.gacategory : 'toasts'
      const label    = eleData.galabel != undefined ? eleData.galabel : 'notification'
      ele.addEventListener('show.bs.toast', (e) => {
        ga('send', 'event', category, 'show', label)
      })
      ele.addEventListener('hide.bs.toast', (e) => {
        ga('send', 'event', category, 'hide', label)
      })
    }
    toast.show()
  })
}

document.addEventListener('turbolinks:load', () => {
  bsToast()
})