'use strict';
export default function validateInputOnBlur (selector) {
  if (document.querySelector(selector) !== null) {
    let inputs = document.querySelectorAll(selector)
    inputs.forEach(function (ele) {
      ele.addEventListener('blur', function (event) {
        ele.classList.remove('is-valid');
        ele.classList.remove('is-invalid');
        if (event.target.validity.valid === false) {
          ele.classList.add('is-invalid');
        } else {
          ele.classList.add('is-valid');
        }
      });
    });
  }
}

document.addEventListener('turbolinks:load', () => {
  validateInputOnBlur('.js-validate-on-blur');
});