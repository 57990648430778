document.addEventListener('turbolinks:load', () => {

  $.fn.expandableCollapse = (expandableSelectorStr, collapsedHeight) => {
    let wrapper = $(expandableSelectorStr);
    if (wrapper.length){
      let expandable = wrapper.find('.js-expandable-content');
      if (expandable.length){
        expandable.on("hidden.bs.collapse", function(){
          expandable.addClass('collapse');
        });
        expandable.on("shown.bs.collapse", function(){
          expandable.removeClass('collapse');
        });
        if ( expandable.height() > collapsedHeight ){
          expandable.addClass('collapse');
          wrapper.find(".js-expander-toggle").addClass('collapsed').removeClass('hide');
        }
      }
    }
  }

  $.fn.expandableInEditable = () => {
    $('a.js-expander-toggle').on('click', function($event){
      let expandable = $(this).siblings('.js-expandable-content');
      expandable.collapse('toggle');
      return false;
    })
  }

  $.fn.expandableCollapse('.js-expander.profile-bio', 120);
  $.fn.expandableInEditable();

})
