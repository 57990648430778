import 'nodelist-foreach-polyfill';

export default function addNameToInput(selector) {
  const inputFields = document.querySelectorAll(selector);
  
  inputFields.forEach( function(ele) {
    ele.addEventListener("keyup", function(e) {
      if (e.target.value === "") {
        this.name = "";
      } else {
        if (this.dataset.name === "location") {
          this.name = "location[]"
        } else {
          this.name = this.dataset.name;
        };
      };
    }, false);
  });
};