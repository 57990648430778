export default function currencyOrFree (cents, currencySymbol = 'USD') {
  if (parseInt(cents, 10) > 0) {
    if (currencySymbol === undefined || currencySymbol === null) {
      currencySymbol = 'USD'
    }
    const symbolUppper = currencySymbol.toString().toUpperCase();
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: symbolUppper,
      minimumFractionDigits: 2
    })

    if (typeof cents == 'number'){
      return formatter.format(cents/100).replace(/(\.|,)00$/g, '')
    } else {
      return formatter.format(parseInt(cents, 10)/100).replace(/(\.|,)00$/g, '')
    }
  } else {
    return 'Free';
  }
}