'use strict'
export const supportedLocales = ['en-US']
import { format } from 'date-fns-tz'
import { isValid, formatRelative, isThisWeek, isThisYear, getDay } from 'date-fns'

export default function dateTimeHelper (ele) {
  let str = ''
  const nowDate = new Date()
  const eleData = ele.dataset
  let dateFormat = eleData.dateformat
  const dateVal = Date.parse(JSON.parse(eleData.datevalue))

  let dateFormatWithYear = function(format) {
    if (isThisYear(dateVal)) {
      return dateFormat.replace('yyyy', '')
    } else {
      return format
    }
  }

  if (isValid(dateVal)) {
    const offsetWeekDay = getDay(nowDate)
    if (isThisWeek(dateVal, { weekStartsOn: offsetWeekDay })) {
      let realtiveStr = formatRelative(dateVal, nowDate, { weekStartsOn: offsetWeekDay }) + ' ' + format(dateVal, 'z')

      // realtiveStr.replace(/^\w/, (c) => c.toUpperCase());
      // str = formatRelative(dateVal, nowDate, { weekStartsOn: offsetWeekDay }) + ' ' + format(dateVal, 'z')
      str = realtiveStr.replace(/^\w/, (c) => c.toUpperCase())

    } else {
      str = format(dateVal, dateFormatWithYear(dateFormat))
    }
    ele.textContent = str
    ele.classList.remove('text-muted')
  } else {
    ele.classList.remove('text-muted')
  }
}

document.addEventListener('turbolinks:load', () => {
  const eles = document.querySelectorAll('.js-local-time')
  Array.prototype.forEach.call(eles, function(ele) {
    dateTimeHelper(ele)
  })
})
