export default function matchAnchorsToIds (selector) {
  if (document.querySelector(selector) !== null) {
    var matchAnchorEles = document.querySelectorAll( selector );
    Array.prototype.forEach.call(matchAnchorEles, function (ele) {
      var anchorTarget = ele.getAttribute("href").replace("#", "");
      if (document.getElementById(anchorTarget)) {
        ele.classList.remove('hide');
      } else {
        return
      }
    });
  }
};