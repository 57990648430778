import storageAvailable from './storageAvailable.js';

document.addEventListener('turbolinks:load', function() {
  $('.js-update-video-filter-on-change').on('change', function() {
    var path = $(this).data('path');
    var search_value = $('input#search').val();
    var goal_value = $(this).val();
    var params = {};
    if (search_value !== '') {
      params['search'] = search_value;
    }
    if (goal_value !== '') {
      params['goal'] = goal_value;
    }
    if (storageAvailable('sessionStorage')){
      sessionStorage.setItem('gaTrackEvent', 'basic filter');
    }
    return window.location.href = path + '?' + ($.param(params));
  });

});
