import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
Vue.use(TurbolinksAdapter)
import resetModal from 'resetModal.js'

export default function eventSpeakers (selector = '.vue-event-speakers') {
  const eles = document.querySelectorAll(selector)
  if (eles.length > 0) {
    const SpeakerGroup = () => import('SpeakerGroup.vue')
    Array.prototype.forEach.call(eles, function(ele) {
      new Vue({ 
        el: ele,
        render: h => h(SpeakerGroup, {
          props: {
            url: ele.dataset.url
          }
        })
      })
    })
    // This removes the modal backdrop from the dom
    document.addEventListener('turbolinks:before-cache', () => {
      resetModal()
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  eventSpeakers()
})