// Old Migrated code
// Required Chapter Leaders & up

document.addEventListener('turbolinks:load', () => {
  if ($('.js-magic-create').length > 0) {
    $('.js-magic-create #admin_registration_registrant_id').on('change', function() {
      return $.ajax({
        url: window.location.pathname + "/registrations/add",
        dataType: 'json',
        data: {
          user_id: $(this).val()
        },
        success: function(data) {
          var key, results, value;
          results = [];
          for (key in data) {
            value = data[key];
            results.push($('.js-magic-create').find("#registration_" + key).val(value));
          }
          return results;
        }
      });
    });
  }
});