import Sortable from 'sortablejs';
import updateAfterSort from 'updateAfterSort.js'

export default function makeSortable(listEleId, btnEleClass, updateFunction) {
    return $(btnEleClass).on('click', function() {
      var sortable, list, sort, sorted;
      list = document.getElementById(listEleId)
      if ($(this).data('editing')) {
        sorted = [];
        sort = $(list).children().each(function() {
          sorted.push(this.dataset.id)
        });
        return updateFunction(sorted, '/admin/team/order'); // function to update order
      } else {
        sortable = new Sortable(list, {
          animation: 150,
          ghostClass: 'bg-blue-faded-c',
          swapThreshold: 10
        });
        $(list).children().toggleClass('cursor-grab');
        $(this).html('Save Team Order');
        return $(this).data('editing', true);
      }
    });
  
}

document.addEventListener('turbolinks:load', function() {
  makeSortable('js-drag-drop-team', '.js-trigger-sortable', updateAfterSort)
});