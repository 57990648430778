'use strict'
import 'nodelist-foreach-polyfill'

export default function confettiAnimation(selector, count = 300) {
  const confettiEles = document.querySelectorAll( selector )
  confettiEles.forEach((ele) => {
    ele.classList.add('confetti-animation')
    for (let i = 0; i <= count; i++) {
      let newPiece = document.createElement('span');
      newPiece.setAttribute('class', 'confetti-piece-' + i)
      ele.appendChild(newPiece)
    }
  })
}