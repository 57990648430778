'use strict';
export default function siteNav (navbars, collapses) {
  const navbarEle = document.getElementsByClassName(navbars)[0];
  const collapseEle = document.getElementsByClassName(collapses)[0];
  const bodyEle = document.getElementsByTagName('body')[0];
  const dropdownEles = document.querySelectorAll('.'+ navbars + ' .dropdown');

  if (navbarEle && collapseEle) {
    const links = navbarEle.querySelectorAll('a:not([href="#"])');
    Array.prototype.forEach.call(links, function (link) {
      link.addEventListener('click', function(event) {
        $(collapseEle).collapse('hide');
      }, false);
    }); 
  }
  if (collapseEle) {
    // This toggles the collpase on mobile after a link is clicked
    $(collapseEle).on('show.bs.collapse', () => {
      bodyEle.classList.add('site-nav-show');
    }).on('hide.bs.collapse', () => {
      bodyEle.classList.remove('site-nav-show');
    });   
  }

  Array.prototype.forEach.call(dropdownEles, function(dropdownEle){
    $(dropdownEle).on('show.bs.dropdown', function () {
      bodyEle.classList.add('site-nav-dropdown-show');
    }).on('hide.bs.dropdown', function () {
      bodyEle.classList.remove('site-nav-dropdown-show');
    });
  });

}

// Handles history events
document.addEventListener('turbolinks:before-cache', () => {
  $(document.getElementsByClassName('js-site-nav-collapse')[0]).collapse('hide');
});

document.addEventListener('turbolinks:load', () => {
  siteNav('js-site-navbar', 'js-site-nav-collapse');
});
