// http://www.xtf.dk/2011/08/center-new-popup-window-even-on.html
// https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
export default function openPopUpWindow (popUpUrl, popUpWidth = 500, popUpHeight = 400) {
  const dualScreenLeft      = window.screenLeft != undefined ? window.screenLeft : window.screenX
  const dualScreenTop       = window.screenTop != undefined ? window.screenTop : window.screenY

  const targetScreenWidth   = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const targetScreenHeight  = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const topPlacement        = ((targetScreenHeight / 2) - (popUpHeight / 2)) + dualScreenTop
  const leftPlacement       = ((targetScreenWidth / 2) - (popUpWidth / 2)) + dualScreenLeft

  const windowFeatureArr    = [
    'directories=no',
    'height=' + popUpHeight, 
    'left=' + leftPlacement, 
    'location=no', 
    'menubar=no', 
    'resizable=yes', 
    'scrollbars=no', 
    'status=no', 
    'toolbar=no', 
    'top=' + topPlacement, 
    'width=' + popUpWidth
  ]

  return window.open(popUpUrl, 'ElleWindow', windowFeatureArr.join())
}
