import 'nodelist-foreach-polyfill';
import replaceInnerText from "./replaceInnerText.js";

export default function filterDropDown(selector) {
  const dropdownElements = document.querySelectorAll(selector);
  
  dropdownElements.forEach( function(ele) {
    const checkboxes = ele.querySelectorAll("input[type='checkbox']");
    const inputs = ele.querySelectorAll(".form-control");
    const labels = ele.querySelectorAll("label", ".form-control");
    const clearButton = ele.querySelectorAll(".js-clear-filters")[0];
    const moreOptionsDropdown = document.getElementsByClassName("js-more-options-button")[0];
    const count = ele.querySelectorAll("input[type='checkbox']:checked").length;
    
    if (ele.id.indexOf("more-options") < 0) {
      replaceInnerText(ele.previousElementSibling, ele);
    } else {
      if (count > 0) {
        moreOptionsDropdown.classList.remove("btn-outline-primary");
        moreOptionsDropdown.classList.add("btn-primary");
      };
    };
    
    labels.forEach( function(label) {
      label.addEventListener("click", function(e) {
        e.stopPropagation();
      }, false);
    });

    inputs.forEach( function(input) {
      input.addEventListener("focus", function(e) {
        e.stopPropagation();
      }, false);
    });
    
    checkboxes.forEach( function(checkbox) {
      checkbox.addEventListener("change", function(e) {
        const param = e.target.name.replace("[]", "");
        const modifiedParam = (param === "job_functions") ? "roles" : param
        const dropdownButton = document.getElementById(modifiedParam + "-filter-button");
        
        if (dropdownButton !== null) {                                                         
          if (e.target.checked == false) {                
            replaceInnerText(dropdownButton, e.target.parentElement.parentElement);
          } else {
            replaceInnerText(dropdownButton, e.target.parentElement.parentElement);
          };
        } else {
          const moreOptionsDropdown = document.getElementsByClassName("js-more-options-button")[0];
          
          if (e.target.checked == false) {
            moreOptionsDropdown.classList.remove("btn-primary");
            moreOptionsDropdown.classList.add("btn-outline-primary");
          } else {
            moreOptionsDropdown.classList.remove("btn-outline-primary");
            moreOptionsDropdown.classList.add("btn-primary");
          };
        };
      }, false);
    });
    
    clearButton.addEventListener("click", function(e) {
      const dropdownButton = document.getElementById(this.dataset.button);
      const moreOptionsDropdown = document.getElementsByClassName("js-more-options-button")[0];
      
      checkboxes.forEach( function(ele) { ele.checked = false; } );
      
      if (dropdownButton === null || dropdownButton.classList.contains("js-more-options-button")) {
        const appendedFiltersDiv = document.querySelectorAll(".js-append-filters")[0];
        const appendedCheckboxes = appendedFiltersDiv.querySelectorAll("input[type='checkbox']");
        
        appendedCheckboxes.forEach( function(ele) { ele.checked = false; } );
        moreOptionsDropdown.classList.remove("btn-primary");
        moreOptionsDropdown.classList.add("btn-outline-primary");
      } else {
        replaceInnerText(dropdownButton, e.target.parentElement.parentElement);
      };
      
      e.stopImmediatePropagation();
    }, false);
  });
};