<template>
  <div v-if="loaded">
    <div v-if="sponsorsPresent">
      <div class="js-anchor-waypoint anchor-offset" id="sponsors"></div>
      <hr>
      <template v-for="(group, index) in sponsorGroups">
        <h3 class="container">{{group.name}}</h3>
        <div class="container-md">
          <div class="row flex-nowrap align-items-center overflow-auto flex-md-wrap gy-4" 
          :class="group.group_display.toLowerCase() == 'small' ? 'row-cols-6 row-cols-lg-10' : 'row-cols-4 row-cols-lg-6'">
            <sponsor-card v-for="sponsor in group.sponsors" :key="sponsor.id" :sponsor="sponsor"></sponsor-card>
          </div>
        </div>
        <hr v-if="!lastGroup(index)" class="spacer">
      </template>
    </div>
  </div>
  <spinner v-else></spinner>
</template>

<script>
  import ax from 'axiosInit'
  import sendAirbrakeError from 'sendAirbrakeError'

  export default {
    name: 'SponsorGroups',
    components: {
      SponsorCard: () => import('SponsorCard.vue'),
      Spinner: () => import('Spinner.vue')
    },
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data: () => ({
      loaded: false,
      sponsorGroups: []
    }),
    methods: {
      lastGroup (index) {
       return (this.sponsorGroups.length - 1) == index
      }
    },
    computed: {
      sponsorsPresent: function(){
        for(var group of this.sponsorGroups) {
          if(group.sponsors && group.sponsors.length > 0){
            return true
          }
        }
        return false
      }
    },
    created: function() {
      const self = this
      ax.get(self.url)
        .then(function (response) {
          self.sponsorGroups = response.data
        })
        .catch(function (error) {
          sendAirbrakeError(error)
        })
        .then(function () {
          self.loaded = true
        })
    }
  }
</script>
