/* eslint no-console:0 */
import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue';
Vue.use(TurbolinksAdapter)

// Components
import UserCardDeck from '../components/UserCardDeck.vue';

document.addEventListener('turbolinks:load', () => {
  const suggestedConnectionElements = document.querySelectorAll('.vue-suggested-users');
  
  if (suggestedConnectionElements != null) {
    for (var i = 0; i < suggestedConnectionElements.length; ++i) {
      const ele = suggestedConnectionElements[i];
      const options = JSON.parse(ele.dataset.options);
      new Vue({ 
        el: ele,
        render: h => h(UserCardDeck, {
          props: {
            options: options
          }
        })
      })
    }
  }
})