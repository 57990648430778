'use strict';

const gaEventOnLoad = () => {
  const trackedEles = document.querySelectorAll('.js-ga-event-on-load');
  Array.prototype.forEach.call(trackedEles, function(ele){
    const eleData = ele.dataset;
    const category = eleData.gacategory != undefined ? eleData.gacategory : '';
    const action = eleData.gaaction != undefined ? eleData.gaaction : '';
    const label = eleData.galabel != undefined ? eleData.galabel : '';
    ga('send', 'event', category, action, location.search);
  });
}

export { gaEventOnLoad };

document.addEventListener('turbolinks:load', () => {
  if (typeof ga !== 'undefined' && ga !== null) {
    gaEventOnLoad();
  }
});