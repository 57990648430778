export default function loadLinkedin() {  
  if (typeof (IN) !== 'undefined') {
    return
  } else {
    const js_script = document.createElement('script')
    js_script.type = "text/javascript"
    js_script.src = 'https://platform.linkedin.com/in.js'
    js_script.defer = true
    document.getElementsByTagName('head')[0].appendChild(js_script)
  }
}

document.addEventListener('turbolinks:load', () => {
  loadLinkedin()
})