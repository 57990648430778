'use strict'
const urlParser = require('query-string')
const gaTrackOnUrl = (queryString = location.search) => {
  const parsed = new urlParser.parse(queryString, {arrayFormat: 'bracket'});
  let action = parsed.gaaction ? parsed.gaaction : '';
  let label = parsed.galabel ? parsed.galabel : '';
  ga('send', 'event', parsed.gacategory, action, label);   
}

export { gaTrackOnUrl };

document.addEventListener('turbolinks:load', () => {
  if (typeof ga !== 'undefined' && ga !== null) {
    if (location.search.indexOf('gacategory=' > 0)) {
      gaTrackOnUrl(location.search);
    }
  }
});