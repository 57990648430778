export default function getGaValuesFromUrl () {
  let url = window.location.pathname;
  let label = url.substring(url.indexOf("/") + 1, url.lastIndexOf("/"));
  let value = url.substring(url.lastIndexOf("/") + 1);
  let gaObj = {
    label: label,
    value: value
  }
  return gaObj;
}
