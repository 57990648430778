export default function expandableList(targetSelector, expandableHolderDiv, dividerNum) {
  let targetEles = document.querySelectorAll(targetSelector);
  Array.prototype.forEach.call(targetEles, function(ele){
    let listItems = ele.querySelectorAll('.js-expandable-list-item');
    let expandableSelector = ele.getAttribute('data-expandable-ele');
    let expandableEle = ele.querySelectorAll(expandableSelector);
    if(listItems.length > dividerNum){
      for(let i = 0; i < listItems.length; i++){
        if (i >= dividerNum && expandableEle.length){
          let currentEle = listItems[i].parentNode.removeChild(listItems[i]);
          expandableEle[0].appendChild(currentEle);
        }
      }

      let expandLink = ele.querySelectorAll('.js-list-expander-toggle');
      expandLink[0].classList.remove('hide');
    }

  });
}