'use strict'
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
Vue.use(TurbolinksAdapter)

export default function userNotifications (selector = '.js-user-notifications') {
  const eles = document.querySelectorAll(selector)
  if (eles.length > 0) {
    const userNotifications = () => import('UserNotifications.vue')
    Array.prototype.forEach.call(eles, function(ele) {
      new Vue({ 
        el: ele,
        render: h => h(userNotifications, {
          props: {
            url: ele.dataset.url
          }
        })
      })
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  userNotifications()
})

// document.addEventListener('turbolinks:load', () => {
//   userNotifications()
// })