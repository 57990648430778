export default function resetModal() {
  const el = document.body;
  const className = 'modal-open';
  const modalBackdropEles = document.querySelectorAll( '.modal-backdrop' );
  if (el.classList) {
    el.classList.remove(className)
  } else {
    new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
  }

  Array.prototype.forEach.call(modalBackdropEles, function (ele) {
    ele.parentNode.removeChild(ele);
  })
}