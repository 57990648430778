export default function currency (cents, currencySymbol = 'USD') {
  if (currencySymbol === undefined || currencySymbol === null) {
    currencySymbol = 'USD'
  }
  const symbolUppper = currencySymbol.toString().toUpperCase();
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: symbolUppper,
    minimumFractionDigits: 2
  });

  if (typeof cents == 'number'){
    return formatter.format(cents/100);
  } else {
    return formatter.format(parseInt(cents, 10)/100);
  }
}