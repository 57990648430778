// Uses IPStack's API to get a visitors lat lng with cell towers
// or internet relays. Returns a response like this:
// "{
//   "ip": "104.225.246.210",
//   "type": "ipv4",
//   "continent_code": "NA",
//   "continent_name": "North America",
//   "country_code": "US",
//   "country_name": "United States",
//   "region_code": "NY",
//   "region_name": "New York",
//   "city": "Manhattan",
//   "zip": "10007",
//   "latitude": 40.71421813964844,
//   "longitude": -74.00817108154297,
//   "location": {
//     "geoname_id": 5125771,
//     "capital": "Washington D.C.",
//     "languages": [{
//       "code": "en",
//       "name": "English",
//       "native": "English"
//     }],
//     "country_flag": "http:\/\/assets.ipstack.com\/flags\/us.svg",
//     "country_flag_emoji": "\ud83c\uddfa\ud83c\uddf8",
//     "country_flag_emoji_unicode": "U+1F1FA U+1F1F8",
//     "calling_code": "1",
//     "is_eu": false
//   }
// }"

export default function geolocateUser (callback) {
  let xhr = new XMLHttpRequest();
  xhr.open('GET', '/geolocate_user.json', true);
  xhr.onreadystatechange = function() {
    if (xhr.readyState == 4 && xhr.status == 200){
      callback(xhr.responseText);
    } else {
      callback(false);
    }
  }
  xhr.send();
}
