'use strict';
const fbqTrackEvents = (selector) => {
  const eles = document.querySelectorAll(selector);
  Array.prototype.forEach.call(eles, function(ele){
    const eleData = ele.dataset;
    const eleEvent = eleData.fbqevent != undefined ? eleData.fbqevent : 'click';
    ele.addEventListener(eleEvent, function(event){
      const category = eleData.fbqcategory != undefined ? eleData.fbqcategory : '';
      const obj = eleData.fbqobj != undefined ? eleData.fbqobj : '';
      // If FB is tracking form submit then we want to make shure the form is valid
      if (ele.tagName === 'FORM' && eleEvent == 'submit') {
        if (ele.checkValidity() === false) {
          return
        }
      }
      if (typeof fbq !== 'undefined') {
        fbq(
          'trackCustom',
          category, JSON.parse(obj)
        );
      } else {
        console.log('%cFacebook pixel is not installed: ' + category + ', ' + obj + '', 'color: #4862a3; background: #fff');
      }
    });
  });
}

export { fbqTrackEvents };

document.addEventListener('turbolinks:load', () => {
  fbqTrackEvents('.js-fbq-track-events');
});