'use strict'
import { Alert } from 'bootstrap'
import Cookies from 'js-cookie'

export default function flashPromo (selector = '.js-flash-promo') {
  const ele = document.querySelector(selector)
  if (ele) {
    const expDate = new Date('2023-10-15T00:00:00')
    ele.addEventListener('closed.bs.alert', event => {
      Cookies.set('elle_promo', false, { expires: expDate })
      ga('send', 'event', 'interaction', 'hide', 'flash promo')
    })  
  }
}

window.addEventListener('turbolinks:load', () => {
  flashPromo()
})