import arrayToSentence from './arrayToSentence';
import capitalize from './capitalize';
import currency from './currency';
import currencyOrFree from './currencyOrFree';
import humanize from './humanize';
import truncate from './truncate';
import yesno from './yesno';


export default {
  install(Vue) {
    Vue.filter('arrayToSentence', arrayToSentence);
    Vue.filter('capitalize', capitalize);
    Vue.filter('currency', currency);
    Vue.filter('currencyOrFree', currencyOrFree);
    Vue.filter('humanize', humanize);
    Vue.filter('truncate', truncate);
    Vue.filter('yesno', yesno);
  }
}