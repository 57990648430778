'use strict'
import 'nodelist-foreach-polyfill'
import { Collapse } from 'bootstrap'

export default function readMoreCollapse (selector = '.js-read-more') {
  const collapseEles = document.querySelectorAll(selector)
  collapseEles.forEach((collapseEle) => {
    Collapse.getInstance(collapseEle, {
      toggle: false
    })
    const collapseToggle = document.querySelector('[data-bs-target="#' + collapseEle.id +'"]')
    const targetMinHeight = getComputedStyle(collapseEle).minHeight

    collapseEle.addEventListener('shown.bs.collapse', () => {
      if (collapseToggle) {
        collapseToggle.textContent = 'Less'
      }
    })
    collapseEle.addEventListener('hide.bs.collapse', () => {
      collapseEle.style.minHeight = targetMinHeight
    })
    collapseEle.addEventListener('hidden.bs.collapse', () => {
      if (collapseToggle) {
        collapseToggle.textContent = 'More'
      }
      collapseEle.style.minHeight = targetMinHeight
    })
  })
}

window.addEventListener('turbolinks:load', () => {
  readMoreCollapse()
})