import getGaValuesFromUrl from 'getGaValuesFromUrl.js'
import openPopUpWindow from 'openPopUpWindow.js'

export default function twitterShare (selector = '.js-twitter-share') {
  let eles = document.querySelectorAll( selector )
  Array.prototype.forEach.call(eles, function (ele) {
    ele.addEventListener("click", function(event) {
      event.preventDefault();
      let gaObj = getGaValuesFromUrl()
      let handle = ele.getAttribute('data-via')
      let url = ele.getAttribute('data-url')
      let params = {
        text: ele.getAttribute('data-text'),
        url: url ? url : document.location.href,
        via: handle ? handle + ' @EllevateNtwk' : 'EllevateNtwk'
      }

      let queryString = Object.keys(params).map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&')

      ga('send', 'event', 'Social Share', 'twitter_share', gaObj.label, gaObj.value)

      openPopUpWindow('https://twitter.com/intent/tweet?' + queryString, 600, 500)

    }, false)
  })
}

document.addEventListener('turbolinks:load', () => {
  twitterShare('.js-twitter-share')
})