import 'nodelist-foreach-polyfill'

export default function dropDownSort(selector) {
  const dropDownSortables = document.querySelectorAll(selector);
  
  dropDownSortables.forEach( function(ele) {
    ele.addEventListener("keyup", function(e) {
      const checkBoxes = Array.prototype.slice.call(this.nextElementSibling.children);
      
      checkBoxes.forEach( function(filter) {
        const value = filter.parentElement.previousElementSibling.children[0].value.toLowerCase();
        const label = filter.lastElementChild.innerText.toLowerCase();
        
        if (value === "") { 
          filter.classList.remove("hide")
        } else if (label.toLowerCase().indexOf(value) >= 0) {
          filter.classList.remove("hide")
        } else {
          filter.classList.add("hide")
        };
      });
    }, false);
  });
};