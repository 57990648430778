// 'use strict';
import Pikaday from 'pikaday';

export default function initDatePicker(selector) {
  const currentDatePickers = [];
  const datepickerEles = document.querySelectorAll(selector);
  function ISODateString(d){
    function pad(n){return n < 10 ? '0' + n : n}
    return d.getFullYear()+'-'
    + pad(d.getMonth()+1)+'-'
    + pad(d.getDate())
  }

  // https://github.com/dbushell/Pikaday
  Array.prototype.forEach.call(datepickerEles, function (ele) {
    if ( currentDatePickers.indexOf(ele.id) > -1 || ele.getAttribute('readonly') ) {
      return;
    } else {
      let todaysDate = new Date();
      let getMinDate = function() {
        if (ele.getAttribute('data-mindate')) {
          return new Date(ele.getAttribute('data-mindate').trim() + ' ');
        } else {
          return null;
        }
      }
      let getStartDate = function() {
        if (ele.getAttribute('data-startdate')) {
          return new Date(ele.getAttribute('data-startdate').trim() + ' ');
        } else {
          return todaysDate;
        }
      }
      let getDefaultDate = function() {
        if (ele.value) {
          return new Date(ele.value.trim() + ' ');          
        } else if (ele.getAttribute('data-defaultdate')) {
          return new Date(ele.getAttribute('data-defaultdate').trim() + ' ');
        } else {
          return null;
        }
      }
      let setDefaultDate = function() {
        if (ele.getAttribute('data-defaultdate') || ele.value) {
          return true;
        } else {
          return false;
        }
      }
      let getMaxDate = function() {
        if (ele.getAttribute('data-maxdate')) {
          return new Date(ele.getAttribute('data-maxdate').trim() + ' ');
        } else {
          return false;
        }
      }

      let picker = new Pikaday({
        field: ele,
        firstDay: 0,
        startDate: getStartDate(),
        defaultDate: getDefaultDate(),
        setDefaultDate: setDefaultDate(),
        minDate: getMinDate(),
        maxDate: getMaxDate(),
        format: 'YYYY-MM-DD',
        theme: 'popover',
        i18n: {
          previousMonth : 'Previous Month',
          nextMonth     : 'Next Month',
          months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
          weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
          weekdaysShort : ['Su','Mo','Tu','We','Th','Fr','Sa']
        },
        toString: function(date, format) {
          return ISODateString(date);
        },
        onSelect: function(date) {
          ele.value = picker.toString();
        },
        onOpen: function() {
          let arr = picker.el.className.split(' ');
          if (arr.indexOf(name) == -1) {
              picker.el.className += ' ' + 'show';
          }
        },
        onClose: function() {
          picker.el.className = picker.el.className.replace(/\bshow\b/g, "");
        }

      });
      currentDatePickers.push(ele.id);
    }
  });

};

document.addEventListener('turbolinks:load', () => {
  initDatePicker('.js-datepicker');
});