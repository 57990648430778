'use strict'
import 'nodelist-foreach-polyfill'

export default function gaEllevate101 (selector = '.js-ellevate-101') {
    const eles = document.querySelectorAll(selector)
    eles.forEach((ele) => {
        //only shown when cookie isn't present, always send shown if there are any elements
        ga('send', 'event', 'Interaction', 'shown', 'Dashboard > Ellevate 101 Alert')

        const ctas = ele.querySelectorAll('.js-ellevate-101-cta')
        ctas.forEach((cta) => {
            cta.addEventListener('click', function (e) {
                Cookies.set('ellevate_101', true, { expires: 1825 });
            })
        })

        ele.addEventListener('close.bs.alert', function (e) {
            Cookies.set('ellevate_101', true, { expires: 1825 });
            ga('send', 'event', 'Interaction', 'hide', 'Dashboard > Ellevate 101 Alert')
        })
    })
}

document.addEventListener('turbolinks:load', () => {
    if (typeof ga !== 'undefined' && ga !== null) {
        gaEllevate101()
    }
});