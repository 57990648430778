'use strict'
import 'nodelist-foreach-polyfill'
import { Modal } from 'bootstrap'

export default function gaTrackModals (selector = '.modal') {
  const eles = document.querySelectorAll(selector)
  eles.forEach((ele) => {
    const eleData  = ele.dataset
    const category = eleData.gacategory != undefined ? eleData.gacategory : 'Modals'
    const label    = eleData.galabel != undefined ? eleData.galabel : ele.id
    ele.addEventListener('show.bs.modal', (e) => {
      ga('send', 'event', category, 'show', label)
    })
    ele.addEventListener('shown.bs.modal', (e) => {
      ga('send', 'event', category, 'shown', label)
    })
    ele.addEventListener('hide.bs.modal', (e) => {
      ga('send', 'event', category, 'hide', label)
    })
    ele.addEventListener('hidden.bs.modal', (e) => {
      ga('send', 'event', category, 'hidden', label)
    })
    ele.addEventListener('hidePrevented.bs.modal', (e) => {
      ga('send', 'event', category, 'hidePrevented', label)
    })
  })
}

document.addEventListener('turbolinks:load', () => {
  if (typeof ga !== 'undefined' && ga !== null) {
    gaTrackModals()
  }
});