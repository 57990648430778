// Old Migrated code
// Required Chapter Leaders & up

var adminEventTickets;
adminEventTickets = (function() {
  function adminEventTickets($element) {
    var $remove;
    var $removeButton;
    var val;
    this.$element = $element;
    this.ticketId = $element.data('ticket');
    this.setupPrice();
    $removeButton = this.$element.find('.remove .btn');
    $removeButton.on('click', (function(_this) {
      return function(event) {
        return _this.toggleDisabled($(event.target));
      };
    })(this));
    $remove = this.inputForAttr('_destroy');
    val = !($remove.val() === 'true' || $remove.val() === true);
    if (!val) {
      $removeButton.html('Add').removeClass('btn-outline-danger').addClass('btn-outline-primary');
      this.$element.find('.js-ticket-toggle-disabled').prop('disabled', true );
    }
  }

  adminEventTickets.prototype.toggleDisabled = function($removeButton) {
    var $remove;
    var val;
    $remove = this.inputForAttr('_destroy');
    val = !($remove.val() === 'true' || $remove.val() === true);
    $remove.val(val);
    if (val) {
      $removeButton.html('Add').removeClass('btn-outline-danger').addClass('btn-outline-primary');
      return this.$element.find('.js-ticket-toggle-disabled').prop('disabled', true );
    } else {
      $removeButton.html('Remove').removeClass('btn-outline-primary').addClass('btn-outline-danger');
      return this.$element.find('.js-ticket-toggle-disabled').prop('disabled', false );
    }
  };

  adminEventTickets.prototype.inputForAttr = function(attr) {
    return this.$element.find("#event_tickets_attributes_" + this.ticketId + "_" + attr);
  };

  adminEventTickets.prototype.setupPrice = function() {
    var $formattedPrice, $price;
    $price = this.inputForAttr('price');
    $formattedPrice = this.inputForAttr('_formatted_price');
    $formattedPrice.on('blur', function() {
      var price;
      if ($(this).val() && $.isNumeric($(this).val())) {
        price = parseFloat($(this).val()).toFixed(2);
        $price.val(parseInt(price * 100));
        $formattedPrice.val(price);
        return $formattedPrice.removeClass('error');
      } else {
        return $formattedPrice.addClass('error');
      }
    });
  };

  return adminEventTickets;

})();

document.addEventListener('turbolinks:load', () => {
  return $('[data-ticket]').each(function() {
    return new adminEventTickets($(this));
  });
});