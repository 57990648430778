<template>
  <div>
    <events-finance-table :eventId="eventId"></events-finance-table>
    <events-coupon-table :eventId="eventId"></events-coupon-table>
  </div>
</template>
<script>
  export default {
    components: {
      EventsFinanceTable: () => import('EventsFinanceTable.vue'),
      EventsCouponTable: () => import('EventsCouponTable.vue')
    },
    props: {
      eventId: {
        type: String,
        required: true
      }
    }
  }
</script>