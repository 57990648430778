import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
import filters from 'filters'
import { Modal } from 'bootstrap'
// import Model from 'bootstrap';
Vue.use(filters);
Vue.use(TurbolinksAdapter);
import resetModal from 'resetModal.js'
import trackContent from 'trackContent.js'

document.addEventListener('turbolinks:load', () => {
  const upgradeModalEle = document.getElementById('upgrade')
  if (upgradeModalEle) {
    // Load in module on modal show
    // document.getElementById('upgrade').addEventListener('show.bs.modal', function (e) {
    const vueAccountUpgradeEle = document.getElementById('vue_account_upgrade')
    const modalInt = Modal.getOrCreateInstance(upgradeModalEle)
    upgradeModalEle.addEventListener('show.bs.modal', function (e) {
      if (vueAccountUpgradeEle != null) {
        trackContent({ action: 'modal show upgrade'})
        const AccountUpgrade = () => import('AccountUpgrade.vue')
        let data = vueAccountUpgradeEle.dataset;
        new Vue({ 
          el: vueAccountUpgradeEle,
          render: h => h(AccountUpgrade, {
            props: {
              url: data.url,
              customer_default_source_url: data.customerDefaultSourceUrl,
              customer_get_active_discount_url: data.customerGetActiveDiscountUrl,
              customer_update_payment_info_url: data.customerUpdatePaymentInfoUrl,
              get_proration_url: data.getProrationUrl,
              join_benefits_url: data.joinBenefitsUrl,
              cta_enterprise_url: data.ctaEnterpriseUrl,
              new_user_plan_url: data.newUserPlanUrl,
              update_coupon_url: data.updateCouponCodeUserPlansUrl,
              copy_obj: JSON.parse(data.translationObj),
              asset_obj: JSON.parse(data.assetsObj)
            }
          })
        })
      }

    })

  }

  document.addEventListener('turbolinks:before-cache', () => {
    resetModal()
  })
})