import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue';
Vue.use(TurbolinksAdapter)

import SponsorGroups from '../components/SponsorGroups.vue';

document.addEventListener('turbolinks:load', () => {
  const eventSponsorsEle = document.getElementById('vue-event-sponsors');
  if (eventSponsorsEle != null) {
    new Vue({ 
      el: eventSponsorsEle,
      render: h => h(SponsorGroups, {
        props: {
          url: eventSponsorsEle.dataset.url
        }
      })
    })
  }
})
